.component {

    &.component--pricelist {
        .pricelist {

            &__subdivision {

                display: flex;
                flex-direction: column;
                gap: 1.5rem;

            }

            &__category {

                display: flex;
                flex-direction: column;
                gap: 1rem;

            }

            &__list {
                display: flex;
                flex-direction: column;
                gap: 0.75rem;
            }

            &__item {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;

                &--subtitle {
                    font-weight: 500;
                }

                .info {
                    display: flex;
                    flex-direction: row;
                    gap: 0.25rem;
                    justify-content: space-between;
                    align-items: start;
                }
                .additional {
                    display: flex;
                    flex-direction: column;
                    gap: 0.25rem;
                    justify-content: space-between;
                    align-items: start;
                    background-color: var(--bg-neutral-100);
                    padding: 1rem 1.25rem;

                    & .nomen {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;

                        & .nomen__title {
                            font-weight: 500;
                        }
                        & .nomen_info {
                            display: flex;
                            flex-direction: row;
                            gap: 0.5rem;
        
                            & .nomen {
                                &__code, &__name{
                                    display: flex;
                                    flex-direction: column;
                                    gap: 0.25rem;
                                }
                                &__name {
                                    
                                }
                            }
                        }
                    }
                }
                
            }
            
        }
    }
}