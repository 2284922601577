.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 16px;
    

    &__pages {
        @include tablet {
            display: flex;
            flex-direction: row;
            gap: 2px;
        }

    }
    &__page {
        display: flex;
        width: 44px;
        height: 44px;
        text-decoration: none;
        border-radius: 50%;
        transition: background-color 0.3s;
        align-items: center;
        justify-content: center;
        font-weight: 500;

        &:hover {
            background-color: var(--bg-neutral-100);
        }

        &--active {
            background-color: var(--secondary);
            color: #fff;
            pointer-events: none;
        }

    }

    &__button {
        display: flex;
        flex-direction: row;
        gap: 0;
        align-items: center;
        font-size: 1rem;

        & .text {
            font-weight: 500;
            font-size: 1rem;
            text-decoration: underline;
        }
    }

}
