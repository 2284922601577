.component {
    &--orgs {
        & .orgs__list {
            
        
            &.orgs--cards {
                display: flex;
                flex-direction: column;
                gap: 0.75rem;
        
                @include tablet {
                    gap: 1rem;
                }
        
                & .org {
                    white-space: normal;
                    padding: 2rem 2rem 2rem 2rem;
                    width: 100%;
                    border-radius: 0.5rem;
                    background-color: var(--bg-neutral-50);
        
                    @include desktop-sm {
                        padding: 2rem;
                    }
        
                    @include desktop-md {
                    }
                    
        
                    &:nth-child(2n) {
                        background-color: var(--primary-600);
                        color: var(--white);
                       .org__name {
                            @include tablet {
                                border-bottom: 2px solid var(--white);
                            }
                        }
                        .footer__name {
                            a {
                                &:after {
                                    background-color: var(--white); 
                                }
                            }
                        }
                    }
        
                    &__inner {
                        
                        display: flex;
                        flex-flow: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: 100%;
                        height: 100%;
                        gap: 0.75rem;
        
                        @include tablet {
                            gap: 0.875rem;
                        }
                        
                    }

                    &__header {
                        width: 100%;
                    }
                    &__name {
                        width: 100%;
                        font-size: 1.25rem;
                        line-height: 1.2;
                        font-weight: 500;
                        border-bottom: 1px solid var(--primary);
                        padding-bottom: 0.75rem;
                        @include tablet {
                            border-bottom: 2px solid var(--primary);
                        }
                        @include desktop-sm {
                            font-size: 1.125rem;
                            
                        }
        
                        & a {
                            display: flex;
                            width: 100%;
                            line-height: 1.2;
        
                        }
                    }
        
                    &__body {
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        justify-content: space-between;
                        gap: 0.5rem;
                        @include tablet {  
                            gap: 0.75rem;
                        }
                    }
                    &__row {
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        gap: 0.25rem;
                        
                        @include tablet {
                            
                            gap: 0.75rem;
                        }
                        & .item {
                            white-space: normal;
                            font-weight: 500;
                            font-size: 1.1rem;

                            &.phone__list,
                            &.fax__list,
                            &.worktime__list {
                                display: flex; 
                                flex-direction: column;
                                gap: 0.5rem;
                                & li {
                                    display: flex;
                                    flex-direction: row;
                                    @include tablet {
                                        justify-content: space-between;
                                    }
                                    gap: 0.5rem;
                                    & .phone__desc {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 0.125rem;
                                        
                                       & .phone__text {
                                        font-weight: 300;
                                        color: var(--primary-300);
                                       }
                                    }

                                    & .fax__desc {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 0.125rem;
                                        color: var(--primary-300);  
                                    }

                                    & .worktime__desc {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 0.125rem;
                                        color: var(--primary-300);
                                        font-weight: 400;
                                    }

                                }
                                
                                & li a{
                                    font-weight: 500;
                                    
                                }   
                                
                            }

                            
                           
                            @include tablet {
                                gap: 1rem;
                            }
                        }
                    }
        
                    &__footer {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: start; 
                        
                        @include tablet {
                            justify-content: end; 
                        }
        
                        a {
                            font-size: 1.1rem;
                            position: relative;

                            &:after {
                                content: ''; 
                                position: absolute; 
                                left: 0;
                                bottom: -2px;
                                width: 100%;
                                height: 0.0875rem; 
                                background-color: var(--primary); 
                                transform: scaleX(1); 
                                transform-origin: bottom left; 
                                transition: transform 0.3s ease; 
                            }
            
                            &:hover:after {
                                transform: scaleX(0); 
                                transform-origin: bottom right; 
                            }
                        }
                    }
                }
            }
        }
    }
}