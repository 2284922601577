.main-nav {
    &__list {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      white-space: nowrap;
      flex-direction: row;
      gap: 12px;

      @include desktop-lg {
        gap: 24px;
      }
    }

    &__item {
      position: static;
      max-width: 240px;
      white-space: normal;
      line-height: 1.1;
      height: 2rem;
      align-content: center;
      vertical-align: middle;

      &:last-child {
        margin-right: 0;
      }


    }


    &__submenu, &__mega-menu {
      display: none;
      position: absolute;
      top:2rem;
      left: 0;
    }

    &__item:hover > &__submenu,
    &__item:hover > &__megamenu {
    }

    &__link {
      color: var(--primary-800);
      text-decoration: none;
      font-weight: 400;
      line-height: 12px;
      padding: 0.5rem 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }