.component {
    &--toc {
        position: sticky;
        top: 0;
        flex-direction: column;
        display: flex;
        background-color: var(--bg-neutral-50);
        padding: 1.25rem;
        border-radius: 1rem;
        
        .toc {
            &__title {
                display: block;
                @include tablet {
                    display: none;
                }
            }

            &__table {
                
            }

            &__list {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                flex-shrink: 0;

                @include tablet {
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 1rem;
                    row-gap: 0.25rem;
                    
                }

            }

            &__item {
                position: relative;
                padding: 0.25rem;
                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0.125rem;
                    left: 0;
                    right: 0;
                    background-color: var(--accent-blue);
                    -webkit-transition: .35s;
                    transition: all .35s ease;
                }

                &.active {
                    font-weight: 500;
                    color: var(--accent-blue);
                    &:before {
                        height: 0.1rem;
                    }
                }
            }
        }

    }
}