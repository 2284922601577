.home {

    & .padding-global{
        padding-left: 0;
        padding-right: 0;
    }
    & .grid-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    
        @include tablet {
            display: grid;
            grid-column-gap: 0.5rem;
            grid-row-gap: 0.5rem;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(5, auto);
        }
    
        @include desktop-md {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 16rem;
            grid-column-gap: 0.75rem;
            grid-row-gap: 0.75rem;
        }
    
        
    }

    & .card {
        &--slider {
  
            @include tablet {
                grid-area: 1 / 1 / 2 / 4;
            }
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            
           
        }
        
        &--phonetable {
            
            @include tablet {
                grid-area: 2 / 1 / 3 / 4;
            }
            @include desktop-sm {
                grid-area: 2 / 1 / 3 / 3;
            }
            @include desktop-md {
                grid-area: 2 / 1 / 6 / 2;
            }
        }
        
        
        &--links-lg {

            @include tablet {
                grid-area: 3 / 1 / 5 / 3;
            }
            @include desktop-sm {
                grid-area: 2 / 3 / 3 / 4;
            }
            @include desktop-md {
                grid-area: 2 / 2 / 4 / 3;
            }
        }
        
        &--vk {
            @include tablet {
                grid-area: 3 / 3 / 4 / 4;
            }
            @include desktop-sm {
                grid-area: 3 / 3 / 4 / 4;
            }
            @include desktop-md {
                grid-area: 4 / 2 / 5 / 3;
            }
        }
        
        &--small-links {
            @include tablet {
                grid-area: 4 / 3 / 5 / 4;
            }
            @include desktop-sm {
                grid-area: 4 / 3 / 5 / 4;
            }
            @include desktop-md {
                grid-area: 5 / 2 / 6 / 3;
            }
        }
        
        &--news {
            @include tablet {
                grid-area: 5 / 1 / 6 / 4;
            }
            @include desktop-sm {
                grid-area: 3 / 1 / 5 / 3;
            }
            @include desktop-md {
                grid-area: 2 / 3 / 6 / 4;
            }
        }

        &--banner {
            grid-row: span 1;
            grid-column: span 3;
            @include tablet {
                grid-area: 6 / 1 / 7 / 4;
            }
            @include desktop-sm {
                grid-area: 5 / 1 / 6 / 4;
            }
            @include desktop-md {
                grid-area: 7 / 1 / 8 / 4;
            }
        }

        &--banners {
            grid-row: span 1;
            grid-column: span 3;
            @include tablet {
                grid-area: 7 / 1 / 8 / 4;
            }
            @include desktop-sm {
                grid-area: 6 / 1 / 7 / 4;
            }
            @include desktop-md {
                grid-area: 8 / 1 / 9 / 4;
            }
        }
        
        &--helpfull {
            @include tablet {
                grid-area: 8 / 1 / 9 / 4;
            }
            @include desktop-sm {
                grid-area: 8 / 1 / 9 / 3;
            }
            @include desktop-md {
                grid-area: 9 / 1 / 10 / 3;
            }
        }
        
        &--map {
            @include tablet {
                grid-area: 9 / 1 / 10 / 4;
            }
            @include desktop-sm {
                grid-area: 8 / 3 / 9 / 4;
            }
            @include desktop-md {
                grid-area: 9 / 3 / 10 / 4;
            }
        }

        
        
    }

    & .card {
        &--slider {

            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .slider {
                &__topline {
                    display: flex;
                    padding: 4px 0;
                    align-items: center;
                    justify-content: center;
                    

                    & .title {
                        display: flex;
                        font-size: 1.125rem;
                        font-weight: 600;
                        color: var(--primary);
                        width: 100%;
                        white-space: nowrap;
                        gap: 30px;

                        &::after, &::before{
                            content: '';
                            position: relative;
                            top: 12px;
                            display: block;
                            height: 2px;
                            width: 100%;
                            background-color: var(--primary);
                        }
                    }
                }
            }

            .swiper--home-slider {
                height: 240px;
                position: relative;
                overflow-x: hidden;
            }
            
            .slider__item {
                height: auto;
                width: 100%;
                @include tablet {
                    height: 220px;
                    width: 428px;
                }
                background-color: var(--neutral);
                border-radius: $border-radius;
            }
        
            .slider__pagination {
                display: none;
                @include tablet {
                    display: block;
                }
            }
            
            
            .swiper-container {
                position: relative;
                left: 0;
                width: 100%;
                padding-bottom: 32px !important;
            
                @include desktop-lg {
                    padding-bottom: 20px !important;
                }
            }
        
            .swiper-wrapper {
                position: relative;
                left: 0;
                width: 100%;
                margin: 0 auto;
                @include desktop-lg {
                    /* Дополнительные стили для desktop-lg */
                }
            }
            
            .swiper-pagination-bullet {
                width: 8px !important;
                height: 8px !important;
                background-color: var(--primary-100) !important;
                opacity: 1 !important;
            
                @include desktop-lg {
                    width: 8px !important;
                    height: 8px !important;
                }
            }
            
            .swiper-pagination-bullet-active {
                background-color: var(--primary) !important;
            }
            
            .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
                margin-left: 8px !important;
                margin-right: 8px !important;
            }
            
            .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
                bottom: 0 !important;
            }
           
        }
        
        &--phonetable {
            justify-content: space-between;
            align-items: flex-start;
            object-fit: fill;
            flex-flow: column;
            background-color: var(--primary);
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            align-self: auto;
            justify-self: auto;

            .card__inner {
                padding: 20px;
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                gap: 10px;
            }

            & .list {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                gap: 10px;
            }
            
            & .item {
                display: grid;
                grid-template-columns: 140px auto;

                @include tablet {
                    grid-template-columns: auto auto;
                }
                
                @include desktop-md{
                    grid-template-columns: 140px auto;
                }
                @include desktop-xl {
                    grid-template-columns: 240px auto;
                }
                align-items: center;
                color: var(--white);
                
                
                & .text {
                    display: flex;
                    flex-flow: column;
                    justify-self: start;
                    gap: 4px;

                    & .title {
                        font-size: 1rem;
                        line-height: 1;
                        font-weight: 400;
                        @include tablet {
                            font-size: 1.25rem;
                        }
                    }

                    & .subtitle {
                        font-size: 0.875rem;
                        font-weight: 300;
                        line-height: 1rem;
                        color: #D9D9D9;
                    }
                }

                & .line{
                    
                    &::before {
                        display: NONE;
                        @include tablet {
                            content: '';
                            display: block;
                            justify-self: center;
                            width: 100%;
                            height: 2px;
                            background: linear-gradient(90deg, rgba(80,80,80,0) 0%, rgba(211,210,210,1) 36%, rgba(211,210,210,1) 72%, rgba(211,210,210,0) 100%);
                        }
                        
                    }
                }

                & .phone {
                    justify-self: end;
                    font-size: 1rem;
                    font-weight: 500;
                    text-decoration: underline;
                    @include tablet {
                        font-size: 1.375rem;
                        text-decoration: none;
                    }
                }
            }
            
        }
        
        
        &--links-lg {
            justify-content: space-between;
            align-items: flex-start;
            object-fit: fill;
            flex-flow: column;
            background-color: #E31E24;
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            align-self: auto;
            justify-self: auto;
            .card__inner {
                padding: 20px;
                height: 100%;
                display: flex;
                flex-direction: column-reverse;
            }

            .items {
                display: flex;
                flex-direction: column;
                gap:10px;

                > a {
                    display: grid;
                    grid-template-columns: auto 20px;
                    align-items: center;
                    column-gap: 10px;
                    border-bottom: 2px solid var(--white);
                    padding-bottom: 10px;


                    .text {
                        font-size: 1.25rem;
                        line-height: 1.1;
                        font-weight: 400;
                        color: var(--white);
                    }
                    .icon {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            
        }
        
        &--vk {
            justify-content: space-between;
            align-items: flex-start;
            object-fit: fill;
            flex-flow: column;
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            align-self: auto;
            justify-self: auto;
            background-color: #0077FF;
            .card__inner {
                height: 100%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                align-content: center;
                justify-content: center;
            }    
        }
        
        &--small-links {
            justify-content: space-between;
            align-items: flex-start;
            object-fit: fill;
            flex-flow: column;
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            align-self: auto;
            justify-self: auto;
            .card__inner {
                display: grid;
                grid-column-gap: 8px;
                grid-row-gap: 8px;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: minmax(80px, auto);
                @include desktop-sm {
                    grid-template-columns: repeat(1, 1fr);
                    grid-template-rows: repeat( 2, 80px);
                }
                @include desktop-md {
                    grid-template-columns: repeat(2, 1fr);
                grid-template-rows: minmax(80px, auto);
                }
            }

            .link {
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                padding-left: 10px;
                padding-right: 10px;
                text-align: center;
                border-radius: 16px;
                background-color: var(--primary-700);
                color: var(--white);
                font-size: 1.25rem;
                line-height: 1.1;

            }
            
        }
        
        &--news {
            justify-content: space-between;
            align-items: flex-start;
            object-fit: fill;
            flex-flow: column;
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            align-self: auto;
            justify-self: auto;
            background-color: #fff0f0;
            color: var(--primary);

            h3 {
                font-size: 1.25rem;
                @include tablet {
                    font-size: 1.125rem;
                }
            }
                .card__inner {
                    display: flex;
                    flex-direction: column;
                    gap: 1.125em;
                    padding: 1.25rem;
                    @include tablet {
                        padding: 1.25rem;
                    }
                }
                .card-heading {
                    display: flex;
                    justify-content: space-between;
                    & .title {

                        & h2 {
                            font-size: 1.05rem;
                            line-height: 1.3;
                            font-weight: 600;

                            @include tablet {
                                font-size: 1.25rem;
                            }
                        }

                    }

                    
                    & .link {
                        display:none;
                        @include tablet {
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                            align-items: center;
                            font-size: 1rem;
                            font-weight: 600;
                            
                            a {
                                position: relative;
                                &:after {
                                    content: ''; 
                                    position: absolute; 
                                    left: 0;
                                    bottom: -2px;
                                    width: 100%;
                                    height: 2px; 
                                    background-color: var(--primary); 
                                    transform: scaleX(1); 
                                    transform-origin: bottom left; 
                                    transition: transform 0.3s ease; 
                                }
                
                                &:hover:after {
                                    transform: scaleX(0); 
                                    transform-origin: bottom right; 
                                }
                            }
                        }
                        
                    }

                }
                
                .list {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    >.item {
                        display: flex;
                        flex-direction: column;
                        gap: 0;
                        padding-bottom: 0.5rem;
                        border-bottom: 2px solid #D9D9D9;
                        
                        @include tablet {
                            gap:0.375rem;
                        }

                        & .top {
                            display: flex;
                            flex-direction: column;
                            align-items: start;
                            gap: 0.25rem;
                            font-size: 0.85rem;
                            color: var(--primary-200);
                            @include desktop-sm {
                                flex-direction: row;
                                justify-content: space-between;
                            }
                            .category {
                                font-size: 0.875rem;
                                font-weight: 600;
                            }
                            .sepatator {
                                &::before {
                                    content: '';
                                    position: static;
                                    display: none;
                                    width: 0.25rem;
                                    height: 0.25rem;
                                    border-radius: 1.25rem;
                                    background-color: var(--primary);
                                }
                                @include desktop-sm {
                                    display: none;
                                }
                            }
                            .date {
                                font-size: 0.9rem;
                                color: var(--primary-300);
                            }                         
                        }
                        .content {
                            display: flex;
                            flex-direction: row;
                            gap: 0.75rem;
                            width: 100%;
                            @include tablet {
                                gap: 1rem;
                            }
                            
                            & .preview {
                                img {
    
                                    display: none;
                                    @include tablet {
                                        display: block;
                                        width: 3.75rem;
                                        height: 3.75rem;
                                        border-radius: 0.75rem;
                                        object-fit: cover;
                                        min-width: 3.75rem;
                                        min-height: 3.75rem;
                                        transition: transform 0.3s ease;
                                    }
                                   
                                }
                            }
                            
                                               
                            
                            & h3 {

                                @include tablet {
                                    line-height: 1.05;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3; /* Количество строк */
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-height: calc(1.125rem * 3 * 1.1);
                                    margin: 0; /* Убираем внешние отступы */
                                    padding: 0; /* Убираем внутренние отступы */
                                    border: none; /* Убираем границы */
                                    transition: color 0.3s ease;
                                }

                            }
                        }

                        &:hover {
                            .content .preview img {
                                transform: scale(1.05);
                            }
                            .content h3 {
                                color: var(--primary-400);
                            }
                        }
                    }

                }

            
        }
        
        &--helpfull {
            background-color: var(--primary);
            color: var(--white);
            border-radius: 20px;
            opacity: 0; /* Начальная прозрачность для анимации */
            transform: translateY(20px); /* Начальное смещение для анимации */
            transition: opacity 0.5s ease, transform 0.5s ease;

            &.loaded {
                opacity: 1; /* Появление */
                transform: translateY(0); /* Смещение в исходное положение */
            }
            .card {
                &__wrapper {
                    padding: 2.5rem;
                }
                &__inner {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                }
        
                &__heading {
                    
                    & .title {
                        font-size: 1.375rem;
                        font-weight: 500;
                    }
                }
        
                &__content {
                    
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    @include tablet {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 1.25rem;

                    }
                    @include desktop-md {
                        grid-template-columns: repeat(3, 1fr);
                    }
                }
        
                &__column {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }
        
                &__item {
        
                    & a {
                        font-size: 1.25rem;
                        line-height: 1;
                        font-weight: 400;
                        position: relative;
                       
                        &:after {
                            content: ''; 
                            position: absolute; 
                            left: 0;
                            bottom: -4px;
                            width: 100%;
                            height: 2px; 
                            background-color: var(--white); 
                            transform: scaleX(1); 
                            transform-origin: bottom left; 
                            transition: transform 0.3s ease; 
                        }

                        &:hover:after {
                            transform: scaleX(0); 
                            transform-origin: bottom right; 
                        }
                    }
                }
            }
        }
        
        &--map {
            background-color: var(--bg-neutral-50);
            border-radius: 20px;
        }

        &--banners {
            
        }
        
        &--banner {
            .banner {
                display: flex;
                flex-direction: column;
                align-items: center;
                align-content: center;
                justify-content: center;
            }    
        }
    }
}