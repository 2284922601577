.main {
	display: flex;
	flex-direction: column;
	gap: 6px;
    min-height: calc(100vh - 130px);
	margin-bottom: 110px;
	margin-top: 90px;
	color: var(--primary);


	@include tablet {
		gap: 16px;
		margin-top: 110px;
	} 

	@include desktop-sm {
		gap: 24px;
		margin-top: 130px;
	}

	& .page-content {
		max-width: 1360px;
	}

	& .grid-col-2 {
		display: grid;
		grid-template-columns: 1fr;
		margin-top: 12px;
		row-gap: 12px;
		max-width: 1360px;

		@include desktop-sm {
			// grid-template-columns: calc(var(--width) - 560px - 40px) 560px;
			grid-template-columns: auto 360px;
			column-gap: 20px;
		}

		@include desktop-md {
			// grid-template-columns: calc(var(--width) - 560px - 40px) 560px;
			grid-template-columns: auto 415px;
			column-gap: 20px;
		}

		& .sec-left {
			order:2;
			display: flex;
			flex-direction: column;
			gap: 16px;
			@include desktop-sm {
				order: 1;
			}
		}

		& .aside {
			order:1;
			display: flex;
			flex-direction: column;
			gap: 10px;
			@include desktop-sm {
				order: 2;
			}
		}

		& .content {
			order:2;
			display: flex;
			flex-direction: column;
			gap: 16px;
			@include desktop-sm {
				order: 1;
			}
		}

		& .sidebar {
			order:1;
			display: flex;
			flex-direction: column;
			gap: 10px;
			position: sticky;
			top: 20px;
			padding: 20px;
			border-radius: 8px;
			height: fit-content;
			@include desktop-sm {
				order: 2;
			}
		}


	}

	&.news--single {
	
			.sec-left {
				order: 1;
				width: 100%;
				overflow-x: hidden;
			}
	
			.aside {
				order: 2;
			}
		}
}