.component {
    &--form {
        .form {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;

            &__description {
                margin-bottom: 1rem;
            }
            &__wrapper {
                width: 100%;
                background-color: var(--bg-neutral-50);
                padding: 2rem 4rem;
            }

            &__field {
                display: flex;
                flex-direction: column;
                gap:0.5rem;
                &--checkbox {
                    flex-direction: row;
                }
               
            }
            &__label {
                font-weight: 700;
                font-size: 1rem;
            }
            input, textarea {
                background-color: var(--white);
                padding: 4px 10px;
                
                &::placeholder {
                    color: #D2DAF0;
                }
            }

            textarea {
                &.large-textarea {
                    width: 100%; /* или укажите конкретное значение, например, 400px */
                    height: 12rem;
                    resize: vertical;
                }
            }
            
            &__actions {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                margin-top: 2rem;
                gap: 2rem;
                justify-content: space-between;
            }

            &__form__notification {
                width: 100%;
            }
            &__submit {
                color: var(--white);
                font-size: 1.15rem;
                font-weight: 500;
                flex-shrink: 0;
                padding: 1.25rem 1.75rem;
                background-color: var(--primary);
                border-radius: 20rem;
            }
        }
    }

    &--multiform {

        .tabs {
            &__nav {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }
        }
        .tabs__content {
            position: relative; /* Для правильного позиционирования панелей */
            padding-top: 1rem;
          }
          
          .tabs__pane {
            display: block; /* Теперь все панели изначально блоковые */
            position: absolute; /* Абсолютное позиционирование для наложения */
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0; /* Скрыты через прозрачность */
            transform: translateY(10px); /* Лёгкий сдвиг вниз для анимации */
            transition: opacity 0.3s ease, transform 0.3s ease; /* Плавный переход */
            pointer-events: none; /* Отключаем взаимодействие со скрытыми панелями */
          }
          
          .tabs__pane--active {
            opacity: 1; /* Появление активной панели */
            transform: translateY(0); /* Возвращаем в исходное положение */
            position: relative; /* Активная панель становится частью потока */
            pointer-events: auto; /* Включаем взаимодействие */
          }
          
          .tabs__radio {
            appearance: none;
            position: absolute;
            opacity: 0;
          }
          
          .tabs__radio-label {
            position: relative;
            cursor: pointer;
            padding-left: 1.5rem;
            display: inline-block;
            font-size: 1.1rem;
          }
          
          .tabs__radio-label::before {
            content: '';
            position: absolute;
            left: 0rem;
            top: 50%;
            transform: translateY(-50%);
            width: 1rem;
            height: 1rem;
            border: 2px solid var(--accent-blue);
            border-radius: 50%;
            background-color: #fff;
            transition: border-color 0.3s ease, background-color 0.3s ease;
          }
          
          .tabs__radio:checked + .tabs__radio-label::before {
            border-color: var(--accent-blue);
            background-color: var(--accent-blue);
          }
          
          .tabs__radio:checked + .tabs__radio-label::after {
            content: '';
            position: absolute;
            left: 0.25rem;
            top: 50%;
            transform: translateY(-50%);
            width: 0.5rem;
            height: 0.5rem;
            background-color: #fff;
            border-radius: 50%;
          }
          
          .tabs__radio:checked + .tabs__radio-label {
            font-weight: bold;
          }
          
          .tabs__radio-group {
            display: inline-block;
          }

        .form {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            
            &__wrapper {
                width: 100%;
                background-color: var(--bg-neutral-50);
                padding: 2rem 4rem;
            }

            &__field {
                display: flex;
                flex-direction: column;
                gap:0.5rem;
                &--checkbox {
                    flex-direction: row;
                }
               
            }
            &__label {
                font-weight: 700;
                font-size: 1rem;
            }
            input, textarea {
                background-color: var(--white);
                padding: 4px 10px;
                
                &::placeholder {
                    color: #D2DAF0;
                }
            }

            textarea {
                &.large-textarea {
                    width: 100%; /* или укажите конкретное значение, например, 400px */
                    height: 12rem;
                    resize: vertical;
                }
            }
            
            &__actions {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                margin-top: 2rem;
                gap: 2rem;
                justify-content: space-between;
            }

            &__form__notification {
                width: 100%;
            }
            &__submit {
                color: var(--white);
                font-size: 1.15rem;
                font-weight: 500;
                flex-shrink: 0;
                padding: 1.25rem 1.75rem;
                background-color: var(--primary);
                border-radius: 20rem;
            }
        }
    }
}