.component {
    &--news-list {
        & .news-list {
            display: block;
            margin-bottom: 2rem;
            
            &--cards {

                & .news-list {
                    &__grid {
                        display: grid;
                        gap: 1rem;
                        @include tablet {
                            grid-template-rows: auto;
                            grid-template-columns: repeat(2, 1fr);
                            grid-auto-rows: max-content;
                        }
                        @include desktop-sm {
                            grid-template-columns: repeat(3, 1fr);
                        }
                        @include desktop-lg {
                            grid-template-columns: repeat(4, 1fr);
                        }
        
                        & .news-list__card {
                            background-color: var(--bg-neutral-50);
                            border-radius: 0.5rem;
                            transition: background-color 0.3s ease;
                            
                            @include tablet {
                                border-radius: 1rem;
                                &:hover {
                                    background-color: var(--bg-neutral-100);
        
                                    & .card__preview img{
                                        transform: scale(1.2); 
                                    }
                                }
                            }
                
                            &.card-default {
                                color: var(--primary);
                                
                                & .card {
                                    
                                    &__inner {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 20px;
                                        padding: 1rem;
        
                                    }
                
                                    &__preview {
                                        height: 14rem;
                                        display: block;
                                        overflow: hidden;
                                        border-radius: 0.5rem;
                                        object-fit: cover;
        
                                        & img {
                                            transition: transform 0.3s ease;  
                                            width: 100%;
                                        }
                                        
                                    }
                
                                    &__content {
                                        
                                        
                                    }
        
                                    &__title {
                                        & h3 {
                                            font-size: 1.15rem;
                                            line-height: 1.1;
                                            font-weight: 400;
                                            white-space: normal;
                                            @include tablet {
                                                height: calc(1.15rem * 1.15 * 5);
                                                overflow-y: hidden;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 5; /* Количество строк */
                                                -webkit-box-orient: vertical;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        } 
                                        
                                    }
            
                                    &__short-description {
                                        
                                    }
                
                                    &__footer {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                        align-items: center;
                
                                        & .card {
                                            &__date {
                                                font-size: 0.9rem;
                                                font-weight: 500;
                                                color: var(--primary-200);
                                            }
                    
                                            &__actions {
                                                
                                                & .btn {
                                                    border-radius: 50px;
                                                    background-color: var(--bg-neutral-100);
                                                    padding: 12px 24px;
                                                    font-size: 0.9rem;
                                                    font-weight: 600;
                                                }
                                            }
                                        }
                
                                    }
                                }
                            }
                        }
                    }
                }
                
            }

            &--list {

                & .news-list {
                    &__grid {
                        display: flex;
                        flex-direction: column;
                        gap: 1.25rem;
        
                        & .news-list__card {
                            background-color: var(--bg-neutral-50);
                            border-radius: 0.5rem;
                            transition: background-color 0.3s ease;
                            
                            @include tablet {
                                border-radius: 1rem;
                                &:hover {
                                    background-color: var(--bg-neutral-100);
        
                                    & .card__preview img{
                                        transform: scale(1.2); 
                                    }
                                }
                            }
                
                            &.card-default {
                                color: var(--primary);
                                
                                & .card {
                                    
                                    &__inner {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 20px;
                                        padding: 2rem;
        
                                    }
                
                                    &__preview {
                                       
        
                                        & img {
                                            transition: transform 0.3s ease;  
                                            width: 100%;
                                        }
                                        
                                    }
                
                                    &__content {
                                        
                                        
                                    }
        
                                    &__title {
                                        & h3 {
                                            font-size: 1.25rem;
                                            line-height: 1.3;
                                            font-weight: 400;
                                            white-space: normal;
                                            margin-bottom: 1rem;
                                            @include tablet {
                                                font-size: 1.35rem;
                                                overflow-y: hidden;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 3; /* Количество строк */
                                                -webkit-box-orient: vertical;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        } 
                                        
                                    }
            
                                    &__short-description {
                                        overflow-y: hidden;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 4; /* Количество строк */
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        color: var(--primary-300);

                                    }
                
                                    &__footer {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                        align-items: center;
                
                                        & .card {
                                            &__date {
                                                font-size: 0.9rem;
                                                font-weight: 500;
                                                color: var(--primary-200);
                                            }
                    
                                            &__actions {
                                                
                                                & .btn {
                                                    border-radius: 50px;
                                                    background-color: var(--primary);
                                                    color: var(--white);
                                                    padding: 0.5rem;
                                                    font-size: 0.9rem;
                                                    font-weight: 600;

                                                    svg {
                                                        width: 1.5rem;
                                                        height: 1.5rem;
                                                    }
                                                }
                                            }
                                        }
                
                                    }
                                }
                            }

                            &.card--collection {
                                color: var(--white);
                                background-color: var(--primary);
                                
                                & .card {
                                    
                                    &__inner {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 20px;
                                        padding: 2rem;
        
                                    }
                
                                    &__preview {
                                       
        
                                        & img {
                                            transition: transform 0.3s ease;  
                                            width: 100%;
                                        }
                                        
                                    }
                
                                    &__content {
                                        
                                        
                                    }
                                    
                                    &__label {
                                        font-size: 0.875rem;
                                        font-weight: 500;
                                        color: var(--primary-200);
                                        margin-bottom: 0.75rem;
                                    }
                                    &__title {
                                        & h3 {
                                            font-size: 1.45rem;
                                            line-height: 1.1;
                                            font-weight: 400;
                                            white-space: normal;
                                            margin-bottom: 1rem;
                                            @include tablet {
                                                font-size: 1.35rem;
                                                overflow-y: hidden;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 2; /* Количество строк */
                                                -webkit-box-orient: vertical;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                line-height: 1.3;
                                            }
                                        } 
                                        
                                    }
            
                                    &__short-description {
                                        overflow-y: hidden;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 4; /* Количество строк */
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                
                                    &__footer {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                        align-items: center;
                
                                        & .card {
                                            &__date {
                                                font-size: 0.9rem;
                                                font-weight: 500;
                                                color: var(--primary-200);
                                            }
                    
                                            &__actions {
                                                
                                                & .btn {
                                                    border-radius: 50px;
                                                    background-color: var(--bg-neutral-100);
                                                    color: var(--primary);
                                                    padding: 0.5rem;
                                                    font-size: 0.9rem;
                                                    font-weight: 600;
                                                    

                                                    svg {
                                                        width: 1.5rem;
                                                        height: 1.5rem;
                                                    }
                                                    &.btn--with-text {
                                                        padding: 0.5rem 1rem;
                                                        display: flex;
                                                        align-items: center;
                                                        gap: 0.5rem;
                                                        line-height: 1.3;

                                                        svg {
                                                            width: 1.5rem;
                                                            height: 1.5rem;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
            
        }
    }
}


.news {
    
    &--list {
        display: block;

        &__wrapper {

        }

        &__inner {
            
        }

        &__list {
            
        }

        & .card {
            
            border-radius: 0.5rem;
            height: 400px;

            @include tablet {
                border-radius: 1rem;
            }

            &-default {
                color: var(--primary);
                
                & .card {
                    &__wrapper {
                        padding: 10px;
                    }
                    
                    &__inner {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                    }

                    &-preview {
                        height: 10rem;
                        display: block;
                        overflow: hidden;
                        border-radius: 0.5rem;
                        object-fit: cover;
                    }

                    &-content {
                        
                        & .title {
                            font-size: 1.125rem;
                            line-height: 1.1;
                            font-weight: 700;
                            white-space: normal;
                        }

                        & .short-description {
                            
                        }
                    }

                    &-footer {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        & .date {
                            font-size: 0.9rem;
                            font-weight: 500;
                            color: var(--primary-200);
                        }

                        & .actions {
                            
                            & .btn {
                                border-radius: 50px;
                                background-color: var(--bg-neutral-100);
                                padding: 12px 24px;
                                font-size: 0.9rem;
                                font-weight: 600;
                            }
                        }

                    }
                }
            }
        }
    }

    &--single {
        
        .news {

            &--recent {

                background-color: var(--bg-neutral-100);
                border-radius: 20px;

                .recent {

                    &__wrapper {
                        padding:24px 40px 40px 40px;

                    }

                    &__inner {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    }

                    &__heading {
                        display: flex;
                        margin-bottom: 1rem;
                        & h3 {
                            font-size: 1.2rem;
                            font-weight: 600;
                        }
                    }

                    &__list {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    }

                    &__item {
                        display: flex;
                        flex-direction: column;
                        min-height: 80px;
                        border-bottom: 1px solid var(--white);
                        padding-bottom: 8px;
                        .item {
                            &--date {
                                font-size: 0.85rem;
                                color: var(--primary-300);
                            }
                            &--title {
                                font-size: 1rem;
                                line-height: 1.2;
                                font-weight: 500;
                                white-space: normal;
                            }
                        }
                        
                    }
                }
            }
 
        }

        .gallery {
            width: 100%;
            display: block;
            margin-top: 2rem;
            
            &__wrapper {


            }

            &__inner {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            &__top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                & .title {
                    font-size: 2.125rem;
                    line-height: 1;
                    font-weight: 500;
                }

                & .gallery__controls {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                }

                
            }    

            &__slider {

                .swiper {

                }
                
                .swiper-wrapper {
                    display: flex;
                    align-items: stretch;

                }
                .swiper-slide {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-align-items: center;
                    align-items: center;
                }
            
                .swiper-slide img {
    
                }

            }
                
            
        }

    }

}