.component {
    &--text-with-link {

        & .component__content {
            margin-top: 1rem;
        }

        & .component__link {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            width: 100%;

            & .link {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 1rem;
                position: relative;
                width: 100%;
                padding: 0.75rem 0;
                font-weight: 500;
                font-size: 1.25rem;

                & span:after {
                    content: ''; 
                    position: absolute; 
                    left: 0;
                    bottom: 0px;
                    width: 100%;
                    height: 2px; 
                    background-color: var(--primary); 
                    transform: scaleX(1); 
                    transform-origin: bottom left; 
                    transition: transform 0.3s ease; 
                }

                &:hover {
                    
                }

                &:hover span:after {
                    transform: scaleX(0); 
                    transform-origin: bottom right; 
                }
                &:hover .icon {
                    transform: translate(0 0); 
                    transform-origin: bottom right; 
                }
            }
        }
    }
}