.component {
    &--hero {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 2rem;

        .breadscrumbs {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            gap:8px;

            .breadscrumbs__item {
                font-size: 0.875rem;
                line-height: 1.2;
                color: var(--primary);
                font-weight: 500;
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }

        .page-title {

        }
    }
}