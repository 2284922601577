.department__info {

    display: flex;
    flex-direction: column;
    gap:20px;
     
    &-leader {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 20px;
        padding: 20px;
        background-color: var(--secondary);
        color: var(--white);
        
        & > .leader {
            &__job {
                font-size: 1.125rem;
                line-height: 1.2;
                font-weight: 300;
            }
            &__name {
                font-size: 1.25rem;
                line-height: 1.2;
                font-weight:400;

            }
            &__phone {
                font-size: 1.375rem;
                line-height: 1.2;
                font-weight:400;
            }
        }

        

    }

    &-contacts {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 20px;
        padding: 20px;
        background-color: var(--primary);
        color: var(--white);
        justify-content: center;

        & .contacts {
            &__list {
                display: flex;
                flex-direction: column;
                gap: 12px;
                font-size: 1.125rem;
                line-height: 1.2;
                font-weight: 300;
            }
            &__item {
                display: flex;
                flex-direction: column;
                gap:10px;
                align-items: center;

                @include tablet {
                    flex-direction: row;
                    justify-content: space-between;
                }

                @include desktop-sm {
                    flex-direction: column;
                    gap:0;
                    justify-content: space-between;
                    align-items: center;

                }

                @include desktop-md {
                    flex-direction: row;
                    gap:0;
                    justify-content: space-between;
                    align-items: center;

                }

                & .item {
                    &__title {
                        font-size: 1rem;
                        line-height: 1.2;
                        font-weight:300;
        
                    }
                    &__phone {
                        font-size: 1.375rem;
                        line-height: 1.2;
                        font-weight:400;
                    }
                }
            }
            
        }

    }

    &-timetable {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 20px;
        padding: 20px;
        background-color: var(--primary);
        color: var(--white);
        justify-content: center;

        .title {
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1.2;
        }

        & .timetable {

            & .item {
                display: flex;
                flex-direction: row;
                gap:10px;
                align-items: center;
                justify-content: space-between;

                & .days {
                    font-size: 1rem;
                    line-height: 1.2;
                    font-weight:200;
        
                }
                & .worktime {
                    font-size: 1.125rem;
                    line-height: 1.2;
                    font-weight:400;
                }
            }
            
        }

    }

}

.department-doctors {
    display: flex;
    flex-direction: column;
    gap:20px;
    width: 100%;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    
    & .sec-title {
        text-align: left;
        font-size: 24px;
        line-height: 1.5;
        margin-bottom: 10px;
    }

    & .sec-intro, .sec-outro {
        
    }

   & .doctors {

    &__list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 1.25rem;
        line-height: 1.2;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background: #F2F4FA;
        font-weight: 500;
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        gap: 20px;
    }

    &__footer {
        display: flex;
        justify-content: center;
    }

    &__header-item {
        flex: 1;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #F2F4FA;
        padding-bottom: 20px;
    }

    &__name{
        flex: 1;
        text-align: left;
        text-decoration: underline;
        font-weight: 500;
    }


    &__specialty {
        flex: 1;
        text-align: right;
    }

    &__link {
        display: block;
        text-align: center;
        margin: 20px 0;
        font-size: 16px;
        text-decoration: none;
        color: #0066cc;

        &:hover {
            text-decoration: underline;
        }
    }

   }
}