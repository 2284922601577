.component {
    &--sitemap {
     
        & .sitemap {
            & ul, ul li {
                list-style: circle;
                list-style: inside;
                list-style-type: disc;
                padding-left: 1rem;
            }

            a {
                color: var(--accent-blue);
            }
        }
    }
}