.overlay {
    &-search {
        display: none;
        height: auto;
        width: 100%;
        position: fixed;
        top: 110px;
        left: 0;
        z-index: 998;
        padding-left: 40px;
        padding-right: 40px;

        &__inner {
            display: flex;
            height: 64px;
            padding-top: 16px;
            padding-bottom: 16px;
            background-color: #fbfbfb;
            border-radius: 20px;
        }

        
        & .search-form {
    
            &__wrapper {
                width: 100%;
                display: flex;
                height: auto;
                padding-left: 16px;
            }
        
            &__field {
                
                display: flex;
                flex-direction: row;
                height: 32px;
                width: 100%;
                background-color: var(--white);
                align-items: center;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 16px;
                padding-right: 16px;
                border-radius: 100px;

                & form {
                    width: 100%;

                    input {
                        width: 100%;
                    }
                }
                
            }
            
        }
        
        &__close {
            width: 48px;
            height: 32px;
        }
    }
}

.overlay-mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    height: var(--height);
    width: var(--width);
    background: #f0f0f080;
    backdrop-filter: blur(20px);

    &-search {

        &__info {
            height: calc(var(--height) - var(--keyboardHeight) - 110px - 20px - 62px);
            width: 100%;
            margin-top: 20px;
        }

        &__bottom {
            height: 80px;
            padding-top: 10px;
            padding-bottom: 20px;
            display: flex;
            justify-content: center;

            & .close {
                & .overlay-mobile-close--search {
                    width: 44px;
                    height: 44px;
                    background-color: #E3000F;
                    border-radius: 50%;
                }
            }

        }
        & .search-form {
    
            &__wrapper {
                display: flex;
                padding-top: 20px;
                height: 62px;
            }
        
            &__field {
                
                display: flex;
                flex-direction: row;
                height: 42px;
                width: 100%;
                background-color: var(--white);
                align-items: center;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 16px;
                padding-right: 16px;
                border-radius: 20px;

                & form {
                    width: 100%;

                    input {
                        width: 100%;
                    }
                }
                
            }
            
        }
    }

    &-contacts {

        &__content {
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: calc(var(--height) - var(--keyboardHeight) - 110px);
            width: 100%;
            margin-top: 20px;
            

            & .address {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #E3000F;
                padding: 20px;
                border-radius: 20px;
                color: var(--white);
            }
            
            & .phones-list {
                display: grid;
                grid-template-columns: 1fr;
                @include mobile {
                    grid-template-columns: 1fr 1fr;
                }
                gap: 10px;


                & .item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    border-radius: 20px;
                    background-color: var(--primary);
                    color: var(--white);
                }
            }
        }

        &__bottom {
            height: 80px;
            padding-top: 10px;
            padding-bottom: 20px;
            display: flex;
            justify-content: center;

            & .close {
                & .overlay-mobile-close--contacts {
                    width: 44px;
                    height: 44px;
                    background-color: #E3000F;
                    border-radius: 50%;
                }
            }

        }

    }
        

}