

.grid {
    display: flex;
    flex-direction: column;

    @include tablet {
        display: grid;
        gap: 1rem;
    }

    &-1 {
        display: grid;
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        grid-template-rows: auto;
        grid-auto-columns: auto;
        background-color: var(--primary-25);
        .col-timetable {
            grid-area: span 1 / span 6;
            background-color: var(--primary);
            @include tablet {
                grid-area: 1 / 1 / 2 / 7;
            }
            @include desktop-sm {
                grid-area: 1 / 1 / 3 / 5;
            }
            @include desktop-md {
                grid-area: 1 / 1 / 6 / 3;
            }
        }
        .col-links {
            grid-area: span 2 / span 6;
            @include tablet {
                grid-area: 2 / 1 / 4 / 5
            }
            @include desktop-sm {
                grid-area: 1 / 5 / 3 / 7;
            }
            @include desktop-md {
                grid-area: 1 / 3 / 3 / 5;
            }
            background-color: var(--primary-100);
        }
        .col-vk {
            grid-area: span 3 / span 6;
            @include tablet {
                grid-area: 2 / 5 / 3 / 7
            }
            @include desktop-sm {
                grid-area: 3 / 5 / 4 / 7;
            }
            @include desktop-md {
                grid-area: 3 / 3 / 4 / 5;
            }
            background-color: var(--primary-400);
        }
        .col-small-link {
            grid-area: span 4 / span 6;
            @include tablet {
                grid-area: 3 / 5 / 4 / 7;
            }
            @include desktop-sm {
                grid-area: 4 / 5 / 5 / 7;
            }
            @include desktop-md {
                grid-area: 4 / 3 / 5 / 5;
            }
            display: grid;
            grid-column-gap: 8px;
            grid-row-gap: 8px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            grid-auto-columns: auto;
            background-color: var(--primary-300);
            .link {
                background-color: var(--primary-700);
            }
        }
        .col-news {
            grid-area: span 5 / span 6;
            @include tablet {
                grid-area: 4 / 1 / 5 / 7;
            }
            @include desktop-sm {
                grid-area: 3 / 1 / 5 / 5;
            }
            @include desktop-md {
                grid-area: 1 / 5 / 6 / 7;
            }
            background-color: var(--primary-200);
        }
    }


    &.grid-3 {
        grid-template-rows: auto;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: max-content;
        & > .span-1 {
        }

        & > .span-2 {
            grid-area: span 1 / span 2 / span 1 / span 2;
        }
    }
}