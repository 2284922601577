.component {
    &--personal {

        &.personal--department {

            & .doctors {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                & .personal__list {

                    flex-direction: column;
                    flex-shrink: 0;
                    display: flex;
                    gap: 0.25rem;
    
                    &.personal__doctors {
                        
                        // & .list__header {
                        //     display: none;
                        //     @include tablet {
                        //         background-color: var(--bg-neutral-100);
                        //         display: grid;
                        //         grid-template-columns: 2fr 1fr 1fr;
                        //         column-gap: 1rem;
                        //         padding: 1.25rem;
                        //         font-size: 1.05rem;
                        //         font-weight: 500;
                        //         border-radius: 0.5rem;
                        //     }
                        // }
    
                        & .list__item {
                                display: flex;
                                flex-direction: column;
                                padding: 1.25rem 1rem;
                                font-size: 1.1rem;
                                column-gap: 1rem;
                                background-color: var(--bg-neutral-50);
                                border-radius: 0.5rem;
                                @include tablet {
                                    flex-direction: row;
                                    justify-content: space-between;
                                }
    
                                transition: background-color 0.3s ease;
                                transition: color 0.3s ease;

                                & .name {
                                    font-weight: 500;
                                }

                                &:hover {
                                    background-color: var(--primary);
                                    color: white;
                                }
                            }
                        
                    }
    
                    
    
                }

                & .doctors__footer {
                    display: flex;
                    flex-direction: column;
                    color: var(--primary);
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    
                    @include tablet {
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: center;
                        align-items: center;
                    }
                    
                    & .link {
                       
                        
                        display: flex; 
                        align-items: center; 
                        position: relative; 
                        text-decoration: none; 
                        background-color: var(--primary);
                        color: var(--white);
                        font-size: 1.15rem;
                        border-radius: 100px;
                        padding: 0.75rem 1.5rem;
                        justify-content: space-between;
                        @include tablet {
                            padding: 0!important;
                            background-color: unset;
                            color: var(--primary);
                            gap: 0.75rem;
                            &:after {
                                content: ''; 
                                position: absolute; 
                                left: 0;
                                bottom: -4px;
                                width: 100%;
                                height: 2px; 
                                background-color: var(--primary); 
                                transform: scaleX(1); 
                                transform-origin: bottom left; 
                                transition: transform 0.3s ease; 
                            }
    
                            &:hover:after {
                                transform: scaleX(0); 
                                transform-origin: bottom right; 
                            }
                        }

                        
                    }

                }
            }
            
            .nursing_staff {
                display: flex;
                flex-direction: column;
                margin-top: 2rem;
            }
        }

        &.doctor--single {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            @include desktop-sm {
                grid-column-gap: 3rem;
                grid-row-gap: 3rem;
                grid-template-rows: auto;
                grid-template-columns: .5fr 1fr;
                grid-auto-columns: 1fr;
                align-items: start;
                display: grid;
              }
            
            & .doctor--single__left {
                display: block;
                @include desktop-sm {
                    position: sticky;
                    top: 0;
                }
            }

            & .doctor--single__right {
            }

            .doctor__hero {
                
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 0.5rem;
                border-bottom: 2px solid var(--primary);
                padding-bottom: 1rem;
                

                @include tablet {
                    background-color: var(--bg-neutral-50);
                    border-radius: 1rem;
                    border-bottom: 0;
                    padding: 3rem;
                }

                & .doctor {
                    &__img {

                    }
                    &__name {
                        font-size: 1.75rem;  
                        @include tablet {
                            font-size: 2rem;  
                        }
                        
                    }
                    &__job {
                        font-size: 1.25rem;
                        @include tablet {
                            font-size: 1.5rem;  
                        }  
                    }
                    &__short_description {
                        
                    }

                    &__department {
                        display: flex;
                        flex-direction: column;
                        gap: 0.75rem;
                        margin-top: 0.5rem;
                        & .departments {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 0.5rem;

                            & li {
                                color: var(--accent-blue);
                                font-size: 1.1rem;
                                font-weight: 500;
                                border-radius: 50rem;

                                & a {
                                    text-decoration: underline;
                                }
                            }
                        }

                    }
                }
            }

            .doctor__info_list {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
                & h4 {
                    font-size: 1.15rem;
                    @include tablet {
                        font-size: 1.25rem;  
                    }   
                }

                & .item {
                    display: flex;
                    flex-direction: column;
                    gap: 0.75rem;

                    &--job {
                        margin-bottom: 1.2rem;
                        & .jobs {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 0.5rem;

                            & li {
                                font-size: 1.1rem;
                                background-color: var(--primary);
                                color: var(--white);
                                padding: 0.75rem 1.25rem;
                                border-radius: 50rem;
                            }
                        }

                    }

                    

                    &--specialty {
                        margin-bottom: 1.2rem;
                        & .specialties {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 0.5rem;

                            & li {
                                font-size: 1.1rem;
                                background-color: var(--primary);
                                color: var(--white);
                                padding: 0.75rem 1.25rem;
                                border-radius: 50rem;
                            }
                        }

                    }
                }
            }
        }
    }
}
