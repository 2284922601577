// Переменные для ширины правой колонки на разных устройствах
$right-column-widths: (
  'mobile-small': 100%,   // 375px
  'mobile': 100%,         // 480px
  'tablet': 250px,        // 768px
  'laptop': 300px,        // 1024px
  'desktop': 350px,       // 1200px
  'desktop-large': 400px, // 1440px
  'desktop-hd': 640px     // 1920px
);

// Точки останова
$breakpoints: (
  'mobile-small': 375px,
  'mobile': 480px,
  'tablet': 768px,
  'laptop': 1024px,
  'desktop': 1200px,
  'desktop-large': 1440px,
  'desktop-hd': 1920px
);

// Миксин для медиа-запросов
@mixin respond-to($breakpoint) {
  @media (min-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

// Стили для контента
.main {
  padding: 0 1.25rem;
  margin: 0 auto;
  width: 100%;
  margin-top: 5rem;
  @include tablet {
    margin-top: 8rem;
  }
  

  &__content {
    display: flex;
    flex-direction: column;

    @include tablet {
      gap: 20px;
    }

    

    .content__column {

      &--left {
        width: 100%;
        order: 1;
        display: flex;
        overflow-wrap: break-word;
        flex-direction: column;
        overflow: hidden;
      }

      &--right {
        display: none;
        order: 999;
        width: 100%;
        align-self: flex-start;
        flex-shrink: 0;
        position: sticky;
        top: 8rem;
        flex-direction: column;
        gap: 1rem;
      }
    }

    &--layout-two-columns {

      @include desktop-sm {
        grid-column-gap: 3rem;
        grid-row-gap: 3rem;
        grid-template-rows: auto;
        grid-template-columns: 1fr .5fr;
        grid-auto-columns: 1fr;
        align-items: start;
        display: grid;
      }

      & .content__column {
        &--right {
          display: flex;
        }
      }

    }
    
  }
}
