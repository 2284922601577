:root {

	--font-main: 'Segoe UI', sans-serif;
	--font-accent: 'Segoe UI', sans-serif;
	--font-titles: var(--font-accent);

	--page-bg: #fff;
	--text-color: var(--primary);
	--accent: #201d1d;
	--accent-blue: #00ABEB;
	--accent-red: var(--secondary);
	--link-color: #00ABEB;

	

	// Colors
	--primary: #435E96;
	--secondary: #E3000F;
	--black: #232323;
	--white: #fff;
	--accent: #00abeb
	--primary-25: #E6EBF4;
	--primary-50: #D5DDEC;
	--primary-100: #C3CEE4;
	--primary-200: #A0B1D4;
	--primary-300: #7D94C5;
	--primary-400: #5977B5;
	--primary-600: #435E96;
	--primary-700: #344974;
	--primary-750: #243351;
	--primary-800: #141D2E;
	--primary-900: #05070B;
	--primary-rgb: 67, 94, 150;

	--neutral-400:#666060;

	--bg-neutral-50: #f9fafd;
	--bg-neutral-100: #f2f4fa;
	
	

	--neutral: #fbfbfb;
	--neutral-graydfdfdf: #dfdfdf;
	--neutral-grayededed: #ededed;
	--neutral-grayf9f9f9: #f9f9f9;
	--neutral-graycbcbcb: #cbcbcb;
	--neutral-grayadadad: #adadad;
	--neutral-gray868686: #868686;
	--neutral-gray606060: #606060;
	--neutral-gray404040: #404040;
	--neutral-gray202020: #202020;
	--neutral-gray0c0c0c: #0c0c0c;
	--state-color-warning-bg: #f7e4c9;
	--state-color-warning-light: #ecbb77;
	--state-color-warning: #e09126;
	--state-color-error-bg: #fff2f2;
	--state-color-error-light: #ed2e2e;
	--state-color-error: #c30000;
	--state-color-success-bg: #f3fdfa;
	--state-color-success-light: #00ba88;
	--state-color-success: #00966d;

	// Fonts
	--font-family: 'Segoe UI', sans-serif;
}

.dark {
	--page-bg: #252526;
	--text-color: #fff;
}

$container-padding-lg: 2.5rem;
$container-padding-md: 2rem;

$container-padding-sm: 1.25rem;

$desktop-xl: 1600px;
$desktop-lg: 1360px;
$desktop-md: 1120px;
$desktop-sm: 952px;
$tablet: 100vw;
$mobile: 100vw;
$mobile-sm: 100vw;

$width-desktop-xl: calc($desktop-xl - $container-padding-lg);
$width-desktop-lg: calc($desktop-lg - $container-padding-lg);
$width-desktop-md: calc($desktop-md - $container-padding-lg);
$width-desktop-sm: calc($desktop-sm - $container-padding-md);
$width-tablet: calc($tablet - $container-padding-md);
$width-mobile: calc($mobile - $container-padding-sm);
$width-mobile-sm: calc($mobile-sm - $container-padding-sm);





$bg-color: var(--white);
$primary-color: #3498db;
$secondary-color: #2ecc71;
$text-color: #333;
$background-color: #f4f4f4;
$button-bg: #fff;
$button-hover-bg: #e1e1e1;
$border-radius: 20px;
