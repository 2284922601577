.top-info {
	display: none;
	@include tablet {
        position: relative;
		z-index: 199;
		padding: 8px 0 7px;
		background: var(--primary-600);
		font-family: var(--font-family);
		font-weight: 400;
		font-size: 0.75rem;
		letter-spacing: 0.07em;
		text-align: center;
		color: var(--white);
		height: 40px;
      }
	
}

