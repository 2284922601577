.accessible-button {
    display: none;
    @include tablet {
        display: flex;
        position: fixed;
        bottom: 1rem;
        left: 1rem;
        height: 2.5rem;
        width: 2.5rem;
        background-color: var(--primary);
        border-radius: 50%;
        color: var(--white);
        padding: 0.5rem;
        z-index: 990;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;
    }
    @include desktop-sm {
        height: 3rem;
        width: 3rem;
    }
    & svg {
        height: 2rem;
        width: 2rem;
        flex-shrink: 0;
    }

    & span {
        display: none;
    }
    &:hover {
        width: auto;
        border-radius: 50rem;
        span {
            display: block;
        }
    }
}