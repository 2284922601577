.typography {
    margin-top: 20px;
    display: block;
    width: 100%;
    font-size: 1.2rem;
    white-space: normal;
    color: var(--primary);
    line-height: 1.3;
    
    & h1, h2, h3 {
        font-weight: 600;
    }

    & h2 {
        font-size: 1.375rem;
        margin-bottom: 10px;
    }

    & h3 {
        font-size: 1.25rem;
        margin-bottom: 10px;
    }


    & ul, ul>li {
        list-style: disc!important;
    }

    & ul {
        margin-left: 30px;
        & li {
            margin-top: 4px;
        }
    }

    & p {
        margin-bottom: 1rem;
    }

    & blockquote {
        padding: 2rem;
        background-color: #F2F4FA;
        display: flex;
    }

    & .bold {
        font-weight: 600;
    }
    & .red {
        color: var(--accent-blue);
    }
    & .blue {
        color: var(--accent-red);
    }
    & .text-bg {
        display: block;
        padding: 2rem;
        background-color: var(--bg-neutral-100);
    }

    .text-center {
        text-align: center;
    }
}

.text-rich-text {
    letter-spacing: -.01em;
    margin-bottom: 1.2rem;
    line-height: 1.3;
    font-size: 1rem;
    text-wrap: wrap;
    text-wrap: stable;
p {
    letter-spacing: -.01em;
    margin-bottom: 1.2rem;
    line-height: 1.3;
    font-size: 1rem;
}

ul {
    
    padding-left: 1rem;
    list-style-type: none;
}

ul li {
    background-image: url(../../img/svg/icon-list-style-1-blue.svg);
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: auto .7rem;
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
    line-height: 1.3;
    list-style-type: none;
}

blockquote {
    background-color: var(--bg-neutral-100);
    padding: 1.25rem;
    border-radius: 0.5rem;
    margin-bottom: 1.2rem;

    &.style-1 {
        font-size: 1.15rem;
        padding: 1.25rem;
        border-radius: 0.5rem;
        margin-bottom: 1.2rem;
        background-color: var(--bg-neutral-100);
    }

    &.style-2 {
        position: relative;
        &:before {
            content: '\201C';
            position: absolute;
            top: 0.25em;
            left: -0.15em;
            color: var(--bg-neutral-50);
            font-size: 6rem;
            z-index: -1;
         }
    }

    &.bg-red {
        background-color: var(--secondary);
        color: var(--white);
    }

    &.bg-blue {
        background-color: var(--accent);
    }
    
}

}