@mixin mediaBg() {
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	    @content;
    }
}

@mixin mobile-sm () {
    @media (min-width: 375px) {
		@content;
	}
}

@mixin mobile () {
    @media (min-width: 480px) {
		@content;
	}
}

@mixin tablet () {
    @media (min-width: 768px) {
		@content;
	}
}

@mixin desktop-sm () {
    @media (min-width: 1024px) {
		@content;
	}
}

@mixin desktop-md () {
    @media (min-width: 1200px) {
		@content;
	}
}

@mixin desktop-lg () {
    @media (min-width: 1440px) {
		@content;
	}
}

@mixin desktop-xl () {
    @media (min-width: 1920px) {
		@content;
	}
}


// Mixins
@mixin container {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
  }