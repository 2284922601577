.cursor {
    position: fixed;
    width: 1rem;
    height: 1rem;
    background-color: rgba(var(--primary-rgb), 0.8); /* Черный полупрозрачный цвет */
    border-radius: 50%; /* Круглый курсор */
    pointer-events: none; /* Чтобы курсор не мешал кликам */
    transform: translate(-50%, -50%); /* Центрируем курсор относительно точки */
    transition: transform 0.1s ease-out, background-color 0.3s ease, width 0.2s ease, height 0.2s ease;
    z-index: 9999; /* Чтобы всегда был поверх всего */
    will-change: transform, background-color, width, height; /* Оптимизация для анимации */
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.cursor.active {
    background-color: var(--primary-700); /* Меняем цвет при активации (например, при клике) */
}

.cursor.link-hover {
    width: 40px;  /* Увеличиваем размер курсора при наведении на ссылку */
    height: 40px;
}

.cursor svg {
    width: 1.5rem;
    height: 1.5rem;

    stroke: var(--white);
}

.cursor-icon {
    display: none; /* Изначально скрываем все иконки */
}

.cursor.icon-visible {
    width: 5rem;
    height: 5rem;
    padding: 1.5rem;
    background-color: var(--primary-700); /* Прозрачный фон для иконок */

    .cursor-icon {
        display: block;

    }
}
