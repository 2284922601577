.component {
    &--toc-mobile {
        position: fixed;
        bottom: 5rem;
        left: 0;
        right: 0;
        background: #fcfcfc60;
        transition: all 0.2s ease-in;
        backdrop-filter: blur(20px);
        border-radius: 1rem;
        z-index: 996;
        @include tablet {
            display: none;
        }
      
        .toc__current-section {
          text-align: center;
          padding: 15px;
          font-size: 16px;
          cursor: pointer;
        }
      
        .toc__list-container {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease;
      
          .toc__list {
            list-style-type: none;
            margin: 0;
            padding: 0;
      
            .toc__item {
              padding: 10px;
              text-align: center;
              border-top: 1px solid #eee;
      
              .toc__link {
                text-decoration: none;
                color: #000;
      
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      
        &.open .toc__list-container {
          max-height: 20rem;
          overflow-y: scroll;
        }
    }
    
}