.banner-phone {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--bg-neutral-50);
    margin-top: 20px;
    padding: 20px 40px;
    line-height: 1.2;
    border-radius: 8px;

    &__wrappper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }

    &__title {
        font-size: 0.875rem;
    }

    &__phone {
        font-size: 1.125rem;
        font-weight: 700;
        @include tablet {
            font-size: 1.375rem;
        }
    }

    &__subtitle {
        font-size: 0.875rem;
    }
}

.list-items {
    
    &--cards-1 {
        margin-top: 20px;
        & .list-items {

            &__wrapper {

            }
    
            &__inner {
                display: flex;
                flex-direction: column;
                gap: 10px;
                @include tablet {
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    gap: 10px;
                }
                @include desktop-sm {
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    gap: 20px;
                }
                @include desktop-md {
                    display: grid;
                    grid-template-columns: repeat(3,1fr);
                }

            }
    
            &__card {
                
                @include tablet {
                    aspect-ratio: 1;
                }
                @include desktop-sm {
                    aspect-ratio: 2/1;
                }
                @include desktop-md{
                    aspect-ratio: 1;
                }
                object-fit: fill;
                border-radius: 8px;
                background-color: var(--bg-neutral-50);
                border-radius: 20px;
                align-self: auto;
                justify-self: auto;

                & svg {
                    fill: var(--primary);
                }
            
                &:nth-child(2n) {
                    background-color: var(--primary-600);
                    color: var(--white);
                    fill: var(--white);

                    & svg {
                        fill: var(--white);
                    }
                }
                & .card {
                    &__wrapper {
                        padding: 2rem;
                        height: 100%;

                        & .tag {
                            display: none;

                            @include tablet {
                                display: block;
                                position: relative;
                                font-size: 3rem;
                                font-weight: 600;
                                opacity: 0.15;
                                line-height: 1;
                            }
                            
                        }
                    }

                    &__inner {
                        display: flex;
                        flex-flow: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: 100%;
                        height: 100%;
                    }

                    &__heading {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: end;
                        align-items: start;

                        @include tablet {
                            justify-content: space-between;
                        }
                    }

                    &__title {
                        width: 100%;
                        padding-right: 20px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: start;
                        font-size: 1.125rem;
                        font-weight: 600;
                        @include tablet {
                            font-size: 1.375rem;
                        }
                        @include desktop-md {
                            font-size: 1.5rem;
                        }
                    }
                }
            }

        }
        
        
    }

    &--cards-2 {
        margin-top: 20px;
        & .list-items {

            &__wrapper {

            }
    
            &__inner {
                display: flex;
                flex-direction: column;
                gap: 10px;
                @include tablet {
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    gap: 10px;
                }
                @include desktop-sm {
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    gap: 20px;
                }
                @include desktop-md {
                    display: grid;
                    grid-template-columns: repeat(3,1fr);
                }

            }
    
            &__card {
                
                @include tablet {
                    aspect-ratio: 4/3;
                }
                @include desktop-sm {
                    aspect-ratio: 3/2;
                }
                @include desktop-md{
                }
                object-fit: fill;
                border-radius: 8px;
                background-color: var(--bg-neutral-50);
                border-radius: 20px;
                align-self: auto;
                justify-self: auto;

                & svg {
                    fill: var(--primary);
                }
            
                &:nth-child(2n) {
                    background-color: var(--primary-600);
                    color: var(--white);
                    fill: var(--white);

                    & svg {
                        fill: var(--white);
                    }
                }
                & .card {
                    &__wrapper {
                        padding: 2rem;
                        height: 100%;

                        & .tag {
                            display: none;

                            @include tablet {
                                display: block;
                                position: relative;
                                font-size: 3rem;
                                font-weight: 600;
                                opacity: 0.15;
                                line-height: 1;
                            }
                            
                        }
                    }

                    &__inner {
                        display: flex;
                        flex-flow: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: 100%;
                        height: 100%;
                    }

                    &__heading {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: end;
                        align-items: start;

                        @include tablet {
                            justify-content: space-between;
                        }
                    }

                    &__title {
                        width: 100%;
                        padding-right: 20px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: start;
                        font-size: 1.125rem;
                        font-weight: 600;
                        @include tablet {
                            font-size: 1.375rem;
                        }
                        @include desktop-md {
                            font-size: 1.5rem;
                        }
                    }
                }
            }

        }
        
        
    }
    
}

.information {
    margin-top: 20px;
    &--title-left {

        .information {
            &__wrapper {}

            &__inner {
                display: flex;
                flex-direction: column;
                gap: 6px;

                @include desktop-lg {
                    gap: 80px;
                    flex-direction: row;
                }

                margin: 0 auto;
            }

            &__heading {
                display: flex;
                width: 100%;

                @include desktop-lg {
                    width: 320px;
                }
            }

            &__title {
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 1.5;

                @include tablet {
                    font-size: 1.625rem;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                width: 100%;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.2;
                border-radius: 8px;

                @include tablet {
                    font-size: 1.125rem;
                }

                &-inner {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    & .intro-text {
                        margin-top: 0px;
                    }   
                }

                
            }

            &__button {
                display: flex;
                justify-content: center;
                @include tablet {
                    justify-content: end;
                }
            }

            

        }
    }

    &--title-top {

        .information {
            &__wrapper {}

            &__inner {
                display: flex;
                flex-direction: column;
                gap: 6px;

                @include desktop-lg {

                }

                margin: 0 auto;
            }

            &__heading {
                display: flex;
                width: 100%;
            }

            &__title {
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 1.5;

                @include tablet {
                    font-size: 1.625rem;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                width: 100%;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.2;
                border-radius: 8px;

                @include tablet {
                    font-size: 1.125rem;
                }

                &-inner {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    & .intro-text {
                        margin-top: 0px;
                    }   
                }

                
            }

            &__button {
                display: flex;
                justify-content: center;
                @include tablet {
                    justify-content: end;
                }
            }

            

        }
    }
}

.requisites {
    font-size: 1rem;
    @include tablet {
        font-size: 1.125rem;
    }

    &-1 {
        display: flex;
        flex-direction: column;
        @include tablet {
            font-size: 1.125rem;
            gap:8px;
        }
        gap:4px;

        & .requisite {
            display: flex;
            flex-direction: row;
            align-items: start;
            margin-bottom: 10px;
            justify-content: space-between;
            gap: 40px;
            
            &__name {
            }

            &__value {
                text-align: right;
                font-weight: 500;
            }
        }
    }
}

.docs {
    font-size: 1rem;
    @include tablet {
        font-size: 1.125rem;
    }

    &-1 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        @include tablet {
            font-size: 1.125rem;
            gap:10px;
        }
        @include desktop-xl {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
            row-gap: 20px;
        }

        & .doc {
            padding: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 40px;
            border-radius: 8px;
            background-color: var(--bg-neutral-50);
            border-bottom: 2px solid var(--bg-neutral-50);
            
            &:nth-child(2n) {
                
               
            }
            &__name {
                font-weight: 500;
            }

            &__action {
            }
        }
    }

    &--cards {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include tablet {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
        @include desktop-md {
            grid-template-columns: repeat(3, 1fr);
        }

        & .doc {
            padding: 2rem 2rem 1rem 2rem;
            height: 100%;
            border-radius: 8px;
            background-color: var(--bg-neutral-50);
            object-fit: fill;
            


            @include tablet {
                aspect-ratio: 4/2;
            }

            @include desktop-md {
                aspect-ratio: 3/2;
            }
            
            & svg {
                fill: var(--primary-600);
            }

            & .doc__action-open {
                // padding: 4px 8px;
                // background-color: var(--primary-600); 
                // border-radius: 20px;
                // color: var(--white);
                // font-size: 0.875rem;
                text-decoration: underline;
            }

            &:nth-child(2n) {
                background-color: var(--primary-600);
                color: var(--white);

                svg {
                    fill: var(--white);
                }
               
            }

            &__inner {
                
                display: grid;
                grid-template-columns: auto 24px;
                column-gap: 20px;

                @include tablet {
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                    height: 100%;
                    gap: 20px;
                }
                
            }
            &__name {
                max-height: 50%;
                font-size: 1rem;
                line-height: 1.2;
                font-weight: 500;

                @include desktop-sm {
                    font-size: 1.125rem;
                }

                & p {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 1.3;
                    -webkit-line-clamp: 8;
                    max-height: calc(1.5em * 8);
                    
                    @include tablet {

                    -webkit-line-clamp: 4;
                    max-height: calc(1.5em * 4);

                    }
                    
                }
            }

            &__action {
                display: none;
                
                @include tablet {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
                
            }

            &__icon {
                display: block;
                
                @include tablet {
                    display: none;
                }
            }
        }
    }

    &--list {
        .docs__category {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            .title {
                display: block;
                width: 100%;
                font-size: 1.25rem;
                font-weight: 500;
            }
            
        }
        .title {
            display: block;
            width: 100%;
            font-size: 1.25rem;
            font-weight: 500;
        }
        .docs__list {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.8rem;
            .doc {
                border-bottom: 2px solid var(--bg-neutral-100);
                padding: 1rem 0;
                &__inner {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;
                    gap: 1rem;
                    
                }
                &__icon {
                   
                    & svg {
                        fill: var(--primary);
                        width: 24px;
                        height: 24px;
                    }
                }

                &__name {
                    width: 100%;
                    font-size: 1rem;
                    text-decoration: underline;
                    color: var(--link-color);
                    white-space: normal;
                }

            }
        }
    }
    
}

.steps {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    @include tablet {
        gap: 2rem;
    }
    &--numbered {

        & .step {
            display: block;
            width: 100%;

            &__inner {
                display: flex;
                flex-direction: row;
                gap: 2rem;
            }
            &__count {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 4rem;
                flex: 0 0 4rem;
                height: 4rem;
                background-color: var(--accent-blue);
                color: var(--white);
                border-radius: 4px;
                font-size: 2rem;
                font-weight: 600;
            }
            &__content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 0.25rem;
            }
            &__title {
                display: flex;
                width: 100%;

                & h3 {
                    font-size: 1.25rem;
                    font-weight: 600;
                    color: var(--primary-600);
                }
            }
            &__description {
                display: block;
                width: 100%;
                font-size: 1.125rem;
                line-height: 1.6;
                white-space: normal;
                margin-top: 0;
            }
        }
    }
    
}

.vacancy {
    display: block;
    margin-top: 20px;

    &__wrapper {

    }

    &__inner {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 1rem;
    }

    &__heading {
        display: flex;
        width: 100%;
    }

    &__title {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.5;

        @include tablet {
            font-size: 1.625rem;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        @include tablet {
            gap: 1rem;
        }

        & .item {
            display: block;
            width: 100%;
            background-color: var(--bg-neutral-50);
            border-radius: 0.5rem;

            &__inner {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                padding: 1.5rem 1.5rem;
                @include tablet {
                    padding: 2rem;
                    gap:2rem;
                }
            }

            &__header {
                display: block;
                width: 100%;
            }

            &__title {
                font-size: 1.25rem;
                font-weight: 600;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: start;

                @include tablet {
                    font-size: 1.5rem;
                }

                & .icon {
                   
                    & svg {
                        fill: var(--primary);
                    }
                }
            }

            &__body {
                display: block;
                width: 100%;
            }

            &__properties-list {
                display: grid;
                grid-template-columns: 1fr;
                
                @include tablet {
                    grid-template-columns: 1fr 1fr;
                }

                @include desktop-md {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                & .property {
                    

                    & .name {

                    }

                    & .value {
                        font-weight: 500;

                        @include tablet {
                            font-size: 1.125rem;
                        }
                    }
                }
            }

            &__footer {
                display: none;
                width: 100%;
                justify-content: end;

                &__actions {
                    display: flex;
                    flex-direction: row;
                    gap: 0.5rem;
                    justify-content: end;
                }
            }
        }
    }
}

