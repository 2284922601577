.component {

    &--contacts-main {
        .contacts-main {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            background-color: var(--bg-neutral-100);
            border-radius: 2rem;
            padding: 1rem;
            @include tablet {
                padding: 2rem;
            }

            &__item {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                
            }
             
            &__title {
                color: var(--primary-400);
                font-weight: 500;
                margin-bottom: 0.5rem;
            }

            &__address {
                &-text {
                    font-size: 1.25rem;
                }
                &-links {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    @include tablet {
                        gap: 1rem;
                    }
                    
                }

                &-link {
                    color: var(--accent-blue);
                    font-weight: 400;


                }
            }

            &__email {

                &-list{
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                }

                &-item {
                    display: flex;
                    flex-direction: column;
                    @include tablet {
                        flex-direction: row;
                        justify-content: space-between;
                    }
                }
                &-link {
                    font-size: 1.25rem;
                }
            }

            &__phones {
                &-wrap {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }

                &-list {
                    display: flex;
                    flex-direction: column;
                    gap: 0.75rem;
                    & .contacts-main__phone {

                        &-item {
                            display: flex;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            gap: 0.5rem;
                            @include tablet {
                                flex-direction: row;
                                gap: 1rem;
                                justify-content: space-between;
                                align-items: start;
                                border-bottom: 2px solid var(--white);
                                padding-bottom: 0.5rem;
                            }
                        }

                        &-label {
                            font-size: 1.25rem;
                        }

                        &-number {
                            flex-shrink: 0;
                            display: flex;
                            flex-direction: column;
                            @include tablet {
                                align-items: flex-end;
                            }
                            & .contacts-main__worktime {
                                font-size: 1.05rem;
                                color: var(--primary-300);
                                text-align: right;
                            }

                        }

                        &-link {
                            font-size: 1.25rem;
                            font-weight: 500;
                        }

                        
                    }
                }

                &-links{
                    margin-top: 0.75rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    gap: 1rem;
                    
                    
                }

                &-link{
                    padding: 0.5rem 1.25rem;
                    border-radius: 2rem;
                    background-color: var(--primary);
                    color: var(--white);
                    font-size: 0.95rem;
                    font-weight: 500;
                    text-decoration: underline;
                }

            }

            &__socials {

                &-list{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                }

                &-link {
                    display: flex;
                    flex-direction: column;
                    @include tablet {
                        flex-direction: row;
                        font-size: 1.15rem;
                        color: var(--accent-blue);
                        justify-content: space-between;
                    }
                    &:after {
                        height: 1px;
                    }
                }
            }
        }

    }

    &--map {

        & .contacts {

            &__wrapper {
                
            }

            &__innner {

            }
        }

        
        & .map {
            height: 240px;


            @include tablet {
                height: 480px;
            }

            & > iframe {
                border-radius: 2rem;
            }
        }
    }
}