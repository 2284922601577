:root {
    --accordion-transition-duration: 0.3s;
  }
  
  /* Общие стили для collapse */
  .collapse, .collapse-mobile {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .collapse-header, .collapse-mobile .collapse-header {
    padding: 15px;
    background-color: #f1f1f1;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .collapse-content, .collapse-mobile .collapse-content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height var(--accordion-transition-duration) ease, opacity var(--accordion-transition-duration) ease;
    padding: 0 15px;
    background-color: #fff;
  }
  
  .collapse.active .collapse-content,
  .collapse-mobile.active .collapse-content {
    max-height: 200px;
    opacity: 1;
  }
  
  .collapse-icon, .collapse-mobile .collapse-icon {
    transition: transform 0.3s ease;
  }
  
  .collapse.active .collapse-icon,
  .collapse-mobile.active .collapse-icon {
    transform: rotate(45deg); /* Меняем иконку при активации */
  }
  
  /* Мобильная версия для collapse-mobile */
  @media (max-width: 768px) {
    .collapse-mobile .collapse-content {
      max-height: 0;
      opacity: 0;
    }
  
    .collapse-mobile.active .collapse-content {
      max-height: 200px;
      opacity: 1;
    }
  }
  
  /* На десктопах блок collapse-mobile всегда открыт */
  @media (min-width: 768px) {
    .collapse-mobile .collapse-content {
      max-height: none;
      opacity: 1;
      overflow: visible;
    }
  }
  