.component {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    

    & h2 {
        margin-bottom: 1rem;
        padding-top: 2rem;
    }
}

