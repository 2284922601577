.btn {
    &-link {
        text-decoration: none;
        border-radius: 100px;
        font-size: 1.125rem;
        font-weight: 400;
        padding: 0.75rem 1.5rem;
        letter-spacing: 1.03;
        color: inherit;
    }
    &-red {
        background-color: var(--secondary);
        color: var(--white);
    }
    &-blue {
        background-color: var(--primary);
        color: var(--white);
    }
    &-neutral {
        background-color: var(--bg-neutral-50);
        color: var(--white);
    }
    &-arrow {
        display: flex;
        gap:10px;
        align-items: center;
    }

    

    &-color {
        &-primary {
            color: var(--primary);
            & > svg {
                fill: var(--primary); 
            }
        }
        
    }
}

