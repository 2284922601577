.mobile-navbar {
	position: fixed;
	bottom: 0;
	left:0;
	width: 100%;
	height: 4.25rem;
	z-index: 99;
	display: block;
	background: var(--neutral);
	transition: all 0.2s ease-in;
	backdrop-filter: blur(20px);
	z-index: 997;
	
	@include tablet {
		display: none;
	}

	&-wrapper {
		display: flex;
		justify-content: space-between;
		margin-left: auto;
		margin-right: auto;
		padding-left: 1.25rem;
		padding-right: 1.25rem;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		gap: 1.25rem;
		max-width: 30rem;
		align-items: center;
	}
	&__item {
		flex-direction: column;
		align-items: center;
		display: flex;
		justify-content: start;
		gap: 0.25rem;
		

		&-icon{
			font-size: 0.625rem;
			width: 2.75rem;
			height: 2.75rem;
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--primary-700);
			& svg {
				// fill: var(--primary-700);
				color: var(--primary-700);
				width: 1.75rem;
				height: 1.75rem;
			}
			& .icon--home-smile, & .icon--phone{
				fill: none;
				color: var(--primary-700);
				
			}
		}
		&.rounded &-icon{
			background-color: var(--primary-700);
			border-radius: 50%;
			color: var(--white)!important;
			& svg {
				color: var(--white)!important;
			}
		}
		
	}
	
}
