.component {
    &--text-dedicated {
        & .component__content {
            background-color: var(--bg-neutral-50);
            padding: 1.25rem 1rem;
            border-radius: 0.25rem;
        }
        &.docs {
            
            &:after {
                
            }
        }
        &.bg-blue {
            background-color: var(--accent-blue);
        }
        &.bg-red {
            background-color: var(--accent-blue);
        }
    }
}