.component {
    &--reception {
        .reception {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
            padding: 1.25rem 2rem;
            color: var(--white);
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.2;
            background-color: var(--primary);
            border-radius: 0.5rem;

            @include tablet {
                font-size: 1.125rem;
                gap: 1rem;
            }


            &__worktime, &__phone, &__location {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
                @include tablet {
                    justify-content: space-between;
                    flex-direction: row;

                }

                & .content {
                    font-size: 1.2rem;
                    font-weight: 600;
                    @include tablet {
                        text-align: end;
                    }

                    & p {
                        font-size: 1.2rem;
                        font-weight: 600;
                    }
                }
            }

            &__worktime {
                & .content {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                }
            }

            &__phone {
                & .content {
                    display: flex;
                    flex-direction: column;
                    gap: 0.25rem;

                    & .subtitle {
                        font-weight: 400;
                    }
                }
            }

        }
    }
}