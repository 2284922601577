.timeline{
    margin-top: 2rem;
    border-radius: 12px;
    position: relative;
  }

  .timeline li{
    padding-bottom: 1.5rem;
    border-left: 0.175rem solid var(--bg-neutral-100);
    position: relative;
    padding-left: 2.5rem;
    margin-left: 1rem;
    &:last-child{
      border: 0px;
      padding-bottom: 0;
      margin-left: 1.175rem;
    }
    &:before{
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background: white;
      border: 0.125rem solid var(--accent-blue);
      border-radius: 50%;
      position: absolute;
      left: -0.85rem;
      top: 0px;
    }
  }
 