@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

* {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html {
	font-size: 16px;
	scroll-behavior: smooth;
	background-color: var(--page-bg);
}

/*
	Fluid typography
*/
html { font-size: calc(0.625rem + 0.41666666666666663vw); }
@media screen and (max-width:1920px) { html { font-size: calc(0.625rem + 0.41666666666666674vw); } }
@media screen and (max-width:1440px) { html { font-size: calc(0.8126951092611863rem + 0.20811654526534862vw); } }
@media screen and (max-width:479px) { html { font-size: calc(0.7494769874476988rem + 0.8368200836820083vw); } }

body {
    color: #202020b3;
    letter-spacing: -.03em;
    font-size: 1rem;
    line-height: 1.2;
	background-color: var(--page-bg);
	color: var(--primary);
	font-family: var(--font-main);
	text-wrap: wrap;
    text-wrap: stable;
	min-height: 100%;
    margin: 0;

}

h1 {
    letter-spacing: -.06em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.125rem;
    font-weight: 700;
    line-height: 1.15;
    @include tablet {
        font-size: 2.5rem;
    }
}

h2 {
    letter-spacing: -.06em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5rem;
	@include tablet {
		font-size: 2.5rem;
	}
    font-weight: 400;
    line-height: 1
}

h3 {
    letter-spacing: -.03em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1
}

h4 {
    opacity: .7;
    letter-spacing: -.03em;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.15
}

h5 {
    letter-spacing: -.03em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 32px
}

h6 {
    letter-spacing: 0;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 1.15rem;
    font-size: .85rem;
    font-weight: 500;
    line-height: 28px
}

p {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    transition: all cubic-bezier(.19,1,.22,1)
}

img {
	display: block;
}

a {
	color: inherit;
}

code {
	background-color: #e9f1f6;
	padding: 0.2rem;
	border-radius: 4px;
}

pre.code {
	overflow-x: auto;
	background-color: #e9f1f6;
	padding: 1rem;
	border-radius: 4px;
}

.no-scroll {
	overflow: hidden;
}



::-webkit-scrollbar {
	width: 8px;                     /* Ширина вертикальной полосы прокрутки */
	height: 8px;                    /* Высота горизонтальной полосы прокрутки */
}

::-webkit-scrollbar-track {
	background: transparent;            /* Цвет фона трека */
}

::-webkit-scrollbar-thumb {
	background-color: var(--primary);      /* Цвет бегунка */
	border-radius: 10px;            /* Закругление бегунка */
}

::-webkit-scrollbar-thumb:hover {
	background-color: #0056b3;      /* Цвет бегунка при наведении */
}



.white {
	color: var(--white);
}