.sec-page-title {

    padding-top: 8rem;
    padding-bottom: 4rem;
    @include desktop-lg {
        width: 100%;
        max-width: 1360px;
        margin: 0 auto;
    }

    @include desktop-xl {
        width: 100%;
        max-width: 1360px;
        margin: 0 auto;
    }
    & .breadscrumbs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        gap:8px;

        & > .item {
            font-size: 0.875rem;
            line-height: 1.2;
            color: var(--primary);
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 8px;
            &::after {
                
            }
        }

    }

    & .hero__title {
        .title {
            font-size: 1.375rem;
            font-weight: 700;
            line-height: 1.2;
            @include tablet {
                font-size: 2rem;
            }
            @include desktop-md {
                font-size: 2.625rem;
            }
            color: var(--primary);
        }
    }

}

.news {
    &--single {
        & .hero {

            & .breadscrumbs {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                gap:8px;
        
                & > .item {
                    font-size: 0.875rem;
                    line-height: 1.2;
                    color: var(--primary);
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    &::after {
                        
                    }
                }
        
            }

            &__title {
                .title {
                    font-size: 1.375rem;
                    font-weight: 700;
                    line-height: 1.2;
                    white-space: normal;
                    @include tablet {
                        font-size: 2rem;
                    }
                    @include desktop-md {
                        font-size: 2.375rem;
                    }
                    color: var(--primary);
                }
            }

            &__bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 10px;
            }

            &__date {
                font-weight: 500;
                color: var(--primary-200);
            }
        }
        & .preview {
            display: block;
            border-radius: 8px;
            overflow: hidden;
            object-fit: cover;
            &__img {
                width: 100%;
                max-width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
    }
}