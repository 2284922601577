.faq {
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;

    &__title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
    }

    &__item {
        border-bottom: 1px solid #fff;
    }

    &__question {
        padding: 18px 24px;
        cursor: pointer;
        background: var(--bg-neutral-100);
        transition: background 0.3s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;

        &:hover {
            background: var(--bg-neutral-50);
        }
    }

    &__answer {
        max-height: 0;
        overflow: hidden;
        padding: 0 24px;
        background: var(--bg-neutral-50);
        transition: max-height 0.3s ease, padding 0.3s ease;
    }

    &__answer--open {
        padding: 18px 24px 24px ;
        max-height: 200px; /* Adjust this value depending on your content's height */
        overflow: scroll;
    }

    &__icon {
        font-size: 1.5rem;
        transition: transform 0.3s;

        &.open {
            transform: rotate(45deg);
        }
    }
}

.collapse {
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;


    &__item {
        border-bottom: 1px solid #fff;
    }

    &__heading {
        padding: 18px 24px;
        cursor: pointer;
        background: var(--bg-neutral-100);
        transition: background 0.3s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;

        &:hover {
            background: var(--bg-neutral-50);
        }
    }

    &__content {
        max-height: 0;
        overflow: hidden;
        padding: 0 24px;
        background: var(--bg-neutral-50);
        transition: max-height 0.3s ease, padding 0.3s ease;
    }

    &__content--open {
        padding: 18px 24px 24px ;
        max-height: 200px; /* Adjust this value depending on your content's height */
        overflow: scroll;
    }

    &__icon {
        font-size: 1.5rem;
        transition: transform 0.3s;

        &.open {
            transform: rotate(45deg);
        }
    }
}