  .header {
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 199;

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 100%;

      @include desktop-lg {
        justify-content: space-between;
      }
    }

    &__main {   
    }

    &__container {
      
      margin-top: 1rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      margin-bottom: 1rem;
      height: 3rem;

      @include tablet {
        height: 6rem;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 0;
        margin-top: 0;
      }
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    

    &__logo img {
      height: 3rem;

      @include tablet {
        height: 3.5rem;
      }

      @include desktop-md {
        height: 4rem;
      }
    
    }

    &__nav {
      display: none;
      
      @include desktop-md {
        font-size: 1rem;
        letter-spacing: -.04rem;
        height: 3rem;
        display: flex;
        align-items: center;
        height: 3rem;
      }
      @include desktop-lg {
        font-size: 0.95rem;
      }


      .nav {
        &__list {
          list-style: none;
          display: flex;
          margin: 0;
          padding: 0;
          white-space: nowrap;
          flex-direction: row;
          gap: 12px;
    
          @include desktop-lg {
            gap: 24px;
          }
        }
    
        &__item {
          position: static;
          max-width: 240px;
          white-space: normal;
          line-height: 1.1;
          height: 2rem;
          align-content: center;
          vertical-align: middle;
    
          &:last-child {
            margin-right: 0;
          }
    
    
        }
    
        &__link {
          color: var(--primary-800);
          text-decoration: none;
          font-weight: 400;
          line-height: 12px;
          padding: 0.5rem 0;
    
          &:hover {
            text-decoration: underline;
          }
        }
      }


    }

    &__topline {
      display: none;

      @include desktop-md {
        height: 24px;
        display: flex;
        justify-content: space-between;
        font-size: 0.875rem;
        letter-spacing: -0.04rem;
        align-items: center;
        color: var(--primary-700);
        background-color: var(--primary-300);
      }
    }


  }

  .topline {

    &__inner {
      display: flex;
      justify-content: space-between;
    }
    &__right {
      display: flex;
      justify-content: baseline;
      gap: 10px;
    }
    &__links {
      display: flex;
      flex-direction: row;

      & nav {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 8px;
      }
      & a {
        display: flex;
        flex-wrap: nowrap;
        text-decoration: underline;
        text-wrap: nowrap;
      }

      // &::after {
      //   content: '';
      //   display: flex;
      //   width: 2px;
      //   height: auto;
      //   background-color: #000;
      //   margin: 4px 2px;
      // }
    }
    &__actions {
      display: flex;
      justify-content: center;
    }
    &__button {
      display: flex;
      flex-direction: row;
      font-weight: 600;
      font-size: 0.95rem;
      color: var(--primary-700);
    }
  }

  .submenu--one-column {
    position: absolute;
    top: 110px;
    display: grid;
    grid-template-columns: 240px;
    background-color: var(--neutral);
    border-radius: $border-radius;
    padding: 32px 40px 32px 32px;
    gap: 12px;
    color: var(--primary-700);
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;

    a {
      color: inherit;
    }
  }

  .submenu--mega-menu {
    position: absolute;
    top: 110px;
    left: 40px;
    width: calc(100% - 80px);
    
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: var(--neutral);
    border-radius: $border-radius;
    padding: 32px 40px 32px 32px;
    gap: 30px;
    color: var(--primary-700);
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;

    & .submenu__column {
      display: flex;
      flex-direction: column;
      gap: 12px;
      line-height: 1;

      & .nav__link {
        font-weight: 700;
        height: auto;
        padding: 0;
        line-height: 1.125rem;
      }

      & .submenu {
        flex-direction: column;
        display: flex;
        gap: 8px;

        & .submenu__link {
          font-size: 0.9375rem;
          line-height: 1rem;
        }
      }
    }

    a {
      color: inherit;
    }

  }