.component {
    
    &--reviews {

        .reviews {
            &__list {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            &__item {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                padding: 1.5rem 1.5rem;
                background-color: var(--bg-neutral-100);
                border-radius: 0.5rem;

                @include tablet {
                    padding: 2rem;
                    gap:1rem;
                }
                .review {

                    &__wrapper {

                    }

                    &__text {
                        font-size: 1.05rem;
                         @include tablet {
                            font-size: 1.1rem;
                            line-height: 1.3;
                         }
                    }

                    &__footer, &__header {
                        display: flex;
                        justify-content: space-between;
                        padding-top: 0.5rem;
                    }

                    &__author {
                        font-size: 1rem;
                        font-weight: 500;
                    }

                    &__date {
                        font-size: 0.95rem;
                        font-weight: 500;
                    }
                    
                    
                }

                & .button__more {
                    color: var(--accent-blue);
                    font-size: 1.1rem;
                    font-weight: 500;
                }
            }
        }
    }
}