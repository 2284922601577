.subdivision {
    margin-top: 20px;

    &--leader {
        .subdivision {
            &__wrapper {}

            &__inner {
                display: flex;
                flex-direction: column;
                gap: 6px;

                @include desktop-lg {
                    gap: 20px;
                    flex-direction: row;
                }

                margin: 0 auto;
            }

            &__heading {
                display: flex;
                width: 100%;

                @include desktop-lg {
                    width: 480px;
                }
            }

            &__title {
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 1.5;

                @include tablet {
                    font-size: 1.625rem;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 20px 40px;
                color: var(--white);
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.2;
                background-color: var(--secondary);
                border-radius: 8px;

                @include tablet {
                    font-size: 1.125rem;
                }

                &-inner {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    @include tablet {
                        display: grid;
                        grid-template-columns: repeat(2, auto);
                        grid-template-rows: repeat(2, auto);
                        row-gap: 16px;
                        column-gap: auto;
                    }
                }

            }

            &__job {
                display: flex;

                @include tablet {
                    grid-column: 1;
                }
            }

            &__name {
                display: flex;
                font-size: 1.125rem;

                @include tablet {
                    grid-column: 1;
                    font-size: 1.25rem;
                }

            }

            &__phone {
                display: flex;
                font-size: 1.25rem;

                @include tablet {
                    font-size: 1.5rem;
                    grid-column: 2;
                    grid-row: 1 / span 2;
                    align-self: stretch;
                    align-items: center;
                    justify-content: center;
                }
            }

        }
    }

    &--departments {
        .subdivision {
            &__wrapper {}

            &__inner {
                display: flex;
                flex-direction: column;
                gap: 6px;

                @include desktop-sm {
                    gap: 20px;
                    flex-direction: row;
                }

                margin: 0 auto;
            }

            &__heading {
                display: flex;
                width: 100%;

                @include desktop-lg {
                    width: 480px;
                }
            }

            &__title {
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 1.5;

                @include tablet {
                    font-size: 1.625rem;
                }
            }

            &__departments {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 20px 40px;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.2;
                border-radius: 8px;

                @include tablet {
                    font-size: 1.125rem;
                    flex-direction: row;
                }

                &-inner {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    @include tablet {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 24px;
                    }
                }

            }

            &__department {
                display: flex;
                background-color: var(--bg-neutral-50);
                gap: 10px;
                padding: 24px;

                & a {
                    font-size: 1.125rem;
                    font-weight: 500;
                    text-decoration: underline;
                }
            }

        }
    }

    &--reception {
        .subdivision {
            &__wrapper {}

            &__inner {
                display: flex;
                flex-direction: column;
                gap: 6px;
                font-size: 1.25rem;

                @include desktop-lg {
                    gap: 20px;
                }

                margin: 0 auto;
            }

            &__heading {
                display: flex;
            }

            &__title {
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 1.5;

                @include tablet {
                    font-size: 1.625rem;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 20px 40px;
                color: var(--white);
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.2;
                background-color: var(--primary);
                border-radius: 8px;

                @include tablet {
                    font-size: 1.125rem;
                }

                &-inner {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    @include tablet {
                        flex-direction: row;
                        justify-content: space-between;
                        gap: 16px;
                    }
                }

            }

            &__worktime, &__phone, &__location {
                display: flex;
                flex-direction: column;

                @include tablet {
                    grid-column: 1;
                }

                .content {
                    font-weight: 600;
                }
            }

        }
    }

    &--appointment {
        .subdivision {
            &__wrapper {}

            &__inner {
                display: flex;
                flex-direction: column;
                margin: 0 auto;
                gap:6px;
                @include desktop-sm {
                    gap:20px;
                }
            }

            &__heading {
                display: flex;
            }

            &__title {
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 1.5;

                @include tablet {
                    font-size: 1.625rem;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                width: 100%;
                overflow-y: scroll;
                

                &-inner {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }

            }
        }
    }
}

.subdivisions__list {
    margin-top: 20px;

    &-wrapper {

    }
    &-inner {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include tablet {
            gap: 40px;
        }

    }
    &-item {
        display: flex;
        flex-direction: row;
        gap: 40px;
        width: 100%;
        @include desktop-sm {
            display: grid;
            grid-template-columns: 420px auto;
        }

        & .image {
            display: none;

            @include desktop-sm {
                display: block;
                width: 420px;
                height: 280px;
                background-color: var(--bg-neutral-50);
                border-radius: 6px;
            }
            
        }

        & .about {
            display: flex;
            flex-direction: column;
            gap: 10px;
            background-color: var(--bg-neutral-50);
            border-radius: 16px;
            padding: 20px 20px 40px 20px;
            @include desktop-sm {
                background-color: unset;
                padding: 0px;
            }

            &-heading {
                display: flex;
                flex-direction: row;
                border-bottom: 2px solid var(--primary);
                padding-bottom: 10px;
                justify-content: space-between;
                align-items: center;
                @include desktop-sm {
                    border-color: var(--primary);
                }
                & .title {
                    font-size: 1.25rem;
                    list-style: 1.3;
                    font-weight: 700;

                    @include tablet {
                        font-size: 1.5rem;
                    }
                }
            }

            &-body {

                &__inner {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    
                    & .description {
                        font-size: 0.875rem;
                        line-height: 1.2;
                        @include tablet {
                            font-size: 1rem;
                            line-height: 1.3;
                        }
                    }
    
                    & .departments {

                        &.collapse {
                            
                            & .collapse {
                                
                                &__item {
                                    border-bottom: none;
                                }
                                &__heading {
                                    background-color: var(--white);
                                    @include tablet {
                                        background-color: var(--bg-neutral-100);
                                    }
                                }
                                &__content {
                                    background-color: var(--bg-neutral-100);
                                    overflow: hidden;
                                    @include tablet {
                                        background-color: var(--bg-neutral-50);
                                    }

                                    &--open {
                                        max-height: none;
                                        height: auto;
                                    }
                                }
                            }
                        }
                        &-list {
                            display: flex;
                            flex-direction: column;
                            gap: 6px;
                            
                            @include tablet {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                gap: 10px;
                            }

                            & .item {
                                font-size: 1rem;
                                font-weight: bold;
                                line-height: 1.3;
                                @include tablet {
                                    padding: 24px;
                                    background-color: var(--white);
                                }

                                & a {
                                    text-decoration: underline;
                                }
                            }
                        }
    
                    }
                }
            }
        }

        
    }
}