.home-banners {
    height: 8rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 40px;
    }

    .swiper--home-banners {
        height: 8rem;
        position: relative;
        overflow-x: hidden;

        .slider__item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px !important;
            width: 300px;

           @include tablet {
                height: 80px !important;
            }

            border-radius: var(--border-radius);

            & .banner__img {
                width: auto;
                height: 60px;

                @include tablet {
                    height: 80px !important;
                }
                
            }
        }
    }

    .swiper-container {
        width: calc(100%);
        margin: 0 auto;

        @include desktop-lg {
            /* Дополнительные стили для desktop-lg */
        }
    }

    .swiper-wrapper {
        
        width: 100%;
        margin: 0 auto;
        @include desktop-lg {
            /* Дополнительные стили для desktop-lg */
        }
    }

    
    .home-banners__button-next, .home-banners__button-prev {
        display: none;
        @include tablet {
            display: flex;
            z-index: 10;
            width: 38px!important;
            height: 38px;
            background-color: #FFF0F0;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
        }
       
    }
}


