.component {
    &--list {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        background-color: var(--bg-neutral-50);
        padding: 1.25rem 1rem;
        border-radius: 0.5rem;
        
        h3 {
            padding-left: 0.95rem;
        }
        
        ol {
            li::marker {
              font-weight: bold;
              font-size: 1.2rem;
            }
          }

        & .list {
            background-color: var(--bg-neutral-50);
            

            & .item {
                margin-left: 2rem;

            }
            & .title {
                font-size: 1.15rem;
                font-weight: 500;
                padding-bottom: 1rem;
            }
        }
    }
}