.component {
    &--requisites {
        & .requisites {
            &__list {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
            }
            &__item {
                
                    display: flex;
                    flex-direction: row;
                    align-items: start;
                    justify-content: space-between;
                    margin-bottom: 1rem;
                    gap: 5rem;
           
                

                & .label {
                    
                }

                & .value {
                    font-weight: 500;
                    text-align: right;
                    
                    
                }
            }
        }
    }
}