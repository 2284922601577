.component {
    &--next-prev-subdiv {
        flex-direction: column;
        display: flex;
        border-top: 0.1rem solid var(--bg-neutral-100);
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding-top: 1rem;
        font-weight: 500;
        gap: 1.5rem;

        @include tablet {
            flex-direction: row;
            justify-content: space-between;
        }

        & .btn {
            padding: 0.75rem 1.5rem;
            background-color: var(--bg-neutral-50);
            border-radius: 1rem;
        }
    }
}