.overlay {
    &--menu {
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 999;
        display: none;
        height: 100vh;
        background: linear-gradient(235deg, rgba(255, 255, 255, 1) 7%, rgba(255, 240, 240, 1) 100%);
        color: var(--primary);
        width: 100%;
        opacity: 0;
        transition: right 0.5s ease;
        /* Анимация при закрытии */
        
        &.visible {
            right: 0;
            opacity: 1;
        }
        &.hiding {
            right: -100%;
            transition: right 0.5s ease;
        }
        .overlay {
            &__wrapper{
                display: flex;
                flex-direction: column;
                margin-left: 2rem;
                margin-right: 2rem;
            }
        
            &__header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                height: 6rem;
                align-items: center;
        
        
                & >.logo {
                    
                    & img {
                        height: 2rem;
        
                    @include tablet {
                        height: 2.5rem;
                    }
        
                    @include desktop-md {
                        height: 3rem;
                    }
                    }
                }
        
                & >.close {
        
                    & .overlay-close--menu {
                        width: 48px;
                        height: 48px;
                        background-color: #E3000F;
                        border-radius: 50%;
                    }
                    
                }
            }
        
            &__body {
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 3rem;
                display: grid;
                grid-template-columns: 3fr 1fr;
                height: calc(100vh - 48px - 26px - 90px - 86px - 24px);
                overflow-y:hidden;
                column-gap: 60px;
                &--left {
                    padding-right: 10px;
                    height: 100%;
                    overflow-y:auto;
                    .menu__list {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        grid-template-rows: auto;
                        @include desktop-md {
                            grid-template-columns: repeat(3, 1fr);
                        }
                        column-gap:60px;
                        row-gap: 20px;
        
                        .column {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                        }
                        .menu__section {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            
                            & .section {
                                &__title {
                                    font-weight: bold;
                                    font-size: 1.5rem;
                                    line-height: 1;
                                    border-bottom: 1px solid #fff;
                                }
                                &__submenu {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 0.25rem;

                                    & .submenu__item {
                                        & a {
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: space-between;
                                        }
                                        & .item__submenu {
                                            display: flex;
                                            flex-direction: column;
                                            gap: 0.25rem;
                                            padding: 1rem;
                                            border-radius: 0.5rem;
                                            background: var(--white);
                                            position: absolute;
                                            visibility: hidden;
                                            opacity: 0;
                                        }
                                    }
                                }
                            }
                            
                            & a {
                                text-decoration: none;
        
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
        
                        .menu-level--1 {
                            display: flex;
                            justify-content: space-between;
                            padding-bottom: 10px;
                           
        
                            
                        }
        
                        .menu-icon {
                            transition: transform 0.3s ease;
                            margin-left: 5px; /* Добавьте отступ по своему вкусу */
                            vertical-align: middle;
                        }
                        
                        .menu-level--1:hover .menu-icon {
                            transform: rotate(45deg) scale(0.85);
                        }
        
                        .menu-level--2 {
                            padding-top: 8px;
                            font-size: 1rem;
                            line-height: 1.1;
                        }
        
                    }
        
                }
        
                &--right {
                    height: 100%;
                    overflow-y:auto;
                    padding-right: 10px;
                    .menu-container {
                        & .section-title {
                            display: flex;
                            justify-content: space-between;
                            padding-bottom: 10px;
                            font-weight: bold;
                            font-size: 1.5rem;
                            line-height: 1;
                            border-bottom: 1px solid #fff;
                        }
        
                        & .departments-list {
                            display: grid;
                            grid-template-columns: repeat(1, 1fr);
                            grid-template-rows: auto;
                            @include desktop-md {
                                grid-template-columns: repeat(2, 1fr);
                            }
                            column-gap:60px;
                            row-gap: 20px;
                            
                            .column {
                                display: flex;
                                flex-direction: column;
                                gap: 20px;
                            }
                            .menu-section {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
            
                                & a {
                                    text-decoration: none;
            
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
            
                            .menu-level--1 {
                                display: flex;
                                justify-content: space-between;
                                font-weight: bold;
                                font-size: 1rem;
                                line-height: 1;
                                padding-top: 8px;
                            }
            
                            .menu-icon {
                                transition: transform 0.3s ease;
                                margin-left: 5px; /* Добавьте отступ по своему вкусу */
                                vertical-align: middle;
                            }
                            
                            .menu-level--1:hover .menu-icon {
                                transform: rotate(45deg) scale(0.85);
                            }
            
                            .menu-level--2 {
                                padding-top: 8px;
                                font-size: 1rem;
                                line-height: 1.1;
                            }
        
                        }
                        
        
                    }
        
                }
                
            }
        
            &__footer {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                gap: 14px;
                position: absolute;
                bottom: 0;
                width: calc(100% - 80px);
                padding-bottom: 24px;
        
                &::before {
                    content: '';
                    width: 100%;
                    height: 36px;
                    backdrop-filter: blur(10px); 
                }
        
                & .divider {
                    width: 100%;
                    height: 1px;
                    background-color: #D9D9D9;
                }
        
                & .inner {
                    display: flex;
                    justify-content: space-between;
                }
        
                & .socials {
        
                }
        
                & .right nav{
                    display: flex;
                    flex-direction: row;
                    gap: 14px;
        
                    & a {
                        text-decoration: underline;
                        font-size: 1rem;
                    }
                }
        
            }
        }
        
    }
}
.overlay-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    height: 100vh;
    background: linear-gradient(235deg, rgba(255, 255, 255, 1) 7%, rgba(255, 240, 240, 1) 100%);
    color: var(--black);
    width: 100%;
    -webkit-transition: .35s;
    transition: all .35s ease;
    
    &__wrapper{
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 4rem;
        align-items: center;


        & >.logo {
            
            & img {
                height: 2rem;

            @include tablet {
                height: 2.5rem;
            }

            @include desktop-md {
                height: 3rem;
            }
            }
        }

        & >.close {

            & .overlay-close--menu {
                width: 48px;
                height: 48px;
                background-color: #E3000F;
                border-radius: 50%;
            }
            
        }
    }

    &__body {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 3rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: calc(100vh - 48px - 26px - 90px - 86px - 24px);
        overflow-y:hidden;
        column-gap: 60px;
        &--left {
            padding-right: 10px;
            height: 100%;
            overflow-y:auto;
            .menu-container {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: auto;
                @include desktop-md {
                    grid-template-columns: repeat(2, 1fr);
                }
                column-gap:60px;
                row-gap: 20px;

                .column {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
                .menu-section {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    & a {
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .menu-level--1 {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 10px;
                    font-weight: bold;
                    font-size: 1.5rem;
                    line-height: 1;
                    border-bottom: 1px solid #fff;

                    
                }

                .menu-icon {
                    transition: transform 0.3s ease;
                    margin-left: 5px; /* Добавьте отступ по своему вкусу */
                    vertical-align: middle;
                }
                
                .menu-level--1:hover .menu-icon {
                    transform: rotate(45deg) scale(0.85);
                }

                .menu-level--2 {
                    padding-top: 8px;
                    font-size: 1rem;
                    line-height: 1.1;
                }

            }

        }

        &--right {
            height: 100%;
            overflow-y:auto;
            padding-right: 10px;
            .menu-container {
                & .section-title {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 10px;
                    font-weight: bold;
                    font-size: 1.5rem;
                    line-height: 1;
                    border-bottom: 1px solid #fff;
                }

                & .departments-list {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    grid-template-rows: auto;
                    @include desktop-md {
                        grid-template-columns: repeat(2, 1fr);
                    }
                    column-gap:60px;
                    row-gap: 20px;
                    
                    .column {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                    }
                    .menu-section {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
    
                        & a {
                            text-decoration: none;
    
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
    
                    .menu-level--1 {
                        display: flex;
                        justify-content: space-between;
                        font-weight: bold;
                        font-size: 1rem;
                        line-height: 1;
                        padding-top: 8px;
                    }
    
                    .menu-icon {
                        transition: transform 0.3s ease;
                        margin-left: 5px; /* Добавьте отступ по своему вкусу */
                        vertical-align: middle;
                    }
                    
                    .menu-level--1:hover .menu-icon {
                        transform: rotate(45deg) scale(0.85);
                    }
    
                    .menu-level--2 {
                        padding-top: 8px;
                        font-size: 1rem;
                        line-height: 1.1;
                    }

                }
                

            }

        }
        
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 14px;
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        padding-bottom: 24px;

        &::before {
            content: '';
            width: 100%;
            height: 36px;
            backdrop-filter: blur(10px); 
        }

        & .divider {
            width: 100%;
            height: 1px;
            background-color: #D9D9D9;
        }

        & .inner {
            display: flex;
            justify-content: space-between;
        }

        & .socials {

        }

        & .right nav{
            display: flex;
            flex-direction: row;
            gap: 14px;

            & a {
                text-decoration: underline;
                font-size: 1rem;
            }
        }

    }
}
.mobile-overlay-menu {
    display: none;
    background: linear-gradient(235deg, rgba(255, 255, 255, 0.6) 7%, rgba(255, 240, 240, 0.8) 100%);
    backdrop-filter: blur(20px);
    z-index: 998;
    height: var(--height);
    position: fixed;
    top: 0;
    left: 0;
    width: var(--width);
    font-size: 1rem;
    flex-direction: column;
    padding-top: 1rem;
    padding-left: $container-padding-sm;
    padding-right: $container-padding-sm;
    

    &__top {
        height: 3rem;
        margin-bottom: 2rem;
        & img {
            height: 3rem;
        }
    }
    &__content {
        height: calc(var(--height) - 4.25rem - 6rem);
        display: flex;
        flex-direction: column;
        
    }
    &__nav {
        height:calc(var(--height) - 106px - 5rem);
        overflow-y: scroll;
        display: flex;
        font-size: 1rem;
        flex-direction: column;

        & .menu {
        
            &__list {
                display: flex;
                flex-direction: column;
                gap:28px; 
            }

            &__item {
                display: block;
                font-size: 1rem;
                font-weight: 300;
                line-height: 1;
                padding-bottom: 10px;
                font-size: 1.625rem;
                    font-weight: 700;
                    line-height: 1;

                &-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    
                    & span.menu__toggle-submenu {

                        transition: transform 0.3s ease;

                        & svg {
                            width: 24px;
                            height: 24px;
                        } 
                    } 
                    
                }

                &[data-level="0"] {
                    border-bottom: 2px solid var(--white);
                }
            }

            &__sublist {
                padding-top: 0.75rem;
                display: flex;
                flex-direction: column;
                gap:0.5rem;
                transition: all 4s ease;
                transition: transform 0.3s ease;

                &.--open {
                    display: block !important;

                }
                
            } 
            &__subitem {

                display: block;
                
                &.--open {
                    height: 100%;
                    padding-bottom: 10px;

                }
                
                &[data-level="1"] {
                    font-size: 1.125rem;
                    font-weight: 500;
                    line-height: 1.2;

                    & .menu__sublist {
                        padding-top: 4px;
                    }

                    & span.menu__toggle-submenu {

                        & svg {
                            width: 16px;
                            height: 16px;
                        } 
                    }
                }

                &[data-level="2"] {
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1;

                    & .menu__sublist {
                        padding-left: 20px;
                    }

                    

                }
            }
            
            &__sublist {
                display: none;
            }

        }
    }
    
    &__bottom {
        
        display: flex;
        flex-direction: column;
        gap:24px;
        justify-content: start;
        padding-top: 1rem;

        
        & .menu__extra {
            font-size: 1rem;
            color: var(--primary);
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
        }
        & .menu__item {

            & a {
                color: inherit;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -2px;
                    width: 100%;
                    height: 1px;
                    background-color: var(--primary);
                    transform: scaleX(1);
                    transform-origin: bottom left;
                    transition: transform 0.3s ease;
                }

                &:hover:after {
                    transform: scaleX(0);
                    transform-origin: bottom right;
                }
            }
            
        }
 
    }

    & .mobile-overlay-menu__close {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        gap:24px;
        justify-content: center;
        align-items: center;
        height: 4.25rem;
        & .mobile-overlay-close--menu {
            width: 2.75rem;
            height: 2.75rem;
            background-color: #E3000F;
            border-radius: 50%;
        }
    }


}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

