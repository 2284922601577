/* Контейнеры */
.padding-global {

    padding-left: 0;
    padding-right: 0;
    
    @include tablet {
        padding-left: 5vw;
        padding-right: 5vw;
    }
	
    

}