.component {
    &--contacts-list {

        & .contacts-list {
            display: flex;

            &.contacts-list--list {
                flex-direction: column;
                gap: 2rem;

                & .contacts-list__item {
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;
                    justify-content: space-between;
                    border-bottom: 0.125rem solid var(--bg-neutral-100);
                    padding: 0.5rem 0;
                    
                    & .title {
                        font-size: 1.15rem;
                    }

                    & .phone {
                        font-size: 1.25rem;
                        font-weight: 500;
                        flex-shrink: 0;
                    }

                    & .email {
                        font-size: 1.25rem;
                        font-weight: 500;
                        flex-shrink: 0;
                    }

                    & .link {
                        font-size: 1.25rem;
                    }

                    &.contacts-list__item--phone {

                    }

                    &.contacts-list__item--email {
                        
                    }

                    &.contacts-list__item--link {
                        
                    }
                }
            }
        }
    }
}