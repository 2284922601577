.none {
	display: none !important;
}

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.no-scroll {
	overflow-y: hidden;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.d-flex {
	display: flex;
}

.flex-center {
	justify-content: center;
}

.mt-0 {
	margin-top: 0!important;
}

.mb-0 {
	margin-bottom: 0!important;
}

.mt-1 {
	margin-top: 1rem!important;
}

.mb-1 {
	margin-bottom: 1rem!important;
}


.mobile-mb {
	margin-bottom: 12rem;
	@include tablet {
		margin-bottom: unset;
	}
}

.sec-title {
	display: flex;

	h2 {
		font-size: 1.375rem;
		font-weight: 700;
		line-height: 1.5;
	}
}

.animate-on-scroll {
	opacity: 0;
	transform: translateY(20px);
	transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  /* Когда блок становится видимым */
  .animate-on-scroll.visible {
	opacity: 1;
	transform: translateY(0);
  }

  .text-collapsed {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: var(--line-clamp, 3); /* Переменная для динамического количества строк */
    white-space: normal;
	}

.link-underline {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: -2px;
		width: 100%;
		height: 1px;
		background-color: var(--accent-blue);
		transform: scaleX(1);
		transform-origin: bottom left;
		transition: transform 0.3s ease;
	}

	&:hover:after {
		transform: scaleX(0);
		transform-origin: bottom right;
	}
}