.menu {
  &.mainnav {
    .menu {
      &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        gap: 0.75rem;
        @include desktop-md {
          gap: 1.5rem;
        }
      }

      &__item {
        position: relative;

        &--megamenu {
          position: static;
        }

        > a {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0;
          text-decoration: none;
          color: var(--primary-700);
          font-weight: 500;
          line-height: 1;
          transition: text-decoration 0.3s ease;
          padding: 0.5rem 0;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      // Общие стили для подменю
      &__submenu {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        padding: 1.5rem 2rem 2rem 2rem;
        background-color: var(--neutral);
        border-radius: 4px;
        z-index: 1000;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        font-size: 1rem;
        line-height: 1;
        font-weight: 500;
        border-radius: 1rem;

        & .menu__item {
          > a {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: var(--primary-700);
            font-weight: 500;
            line-height: 1.2;
            text-decoration: none;
            padding: 0;

            & .icon {
              svg {
                width: 0.5rem;
                height: 0.5rem;
              }
            }

            &:hover {
              text-decoration: none;
            }
          }
        }

        // Подменю первого уровня (одноколоночное)
        &--single {
          font-size: 1rem;
          min-width: 15rem;
          top: 100%;
          left: 0;
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
        }

        // Подменю первого уровня (двухколоночное)
        &--double {
          width: 400px;
          top: 100%;
          left: 0;
          display: flex;

          .menu__submenu-column {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 10px;
          }
        }

        &--megamenu {
          position: absolute;
          font-size: 1rem;
          width: 100%;
          display: grid;
          top: 100%;
          grid-template-columns: 3fr 1fr;
          gap: 0.75rem;

          & .megamenu {

            &__item {
              display: flex;
              flex-direction: column;
              gap: 0.75rem;
              & > a {
                font-weight: 700;
                color: var(--primary);
                opacity: 0.8;
                font-size: 1.05rem;
                padding: 0;
                
              }
              & .item__submenu {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                & .menu__item {
                  
                }
              }
            }

            &__left {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 0.75rem;
            }

            &__right {
              display: flex;
              flex-direction: column;
              gap: 0.75rem;
            }

            

            
          }


          .menu__column {
            // Стили для колонок мегаменю
            

          }

          & .menu__submenu--default {
            background-color: var(--white)!important;
          }

          
        }

        // Подменю для глубоких вложенностей (начиная со второго уровня)
        &--default {
          min-width: 200px;
          top: 0;
          left: 100%;
          margin-left: 5px;
        }
      }

      // Анимация открытия подменю
      &__item:hover > &__submenu {
        visibility: visible;
        opacity: 1;
      }

      // Анимация индикатора подменю
      &__item:hover > a::after {
        transform: rotate(90deg);
      }
    }
  }
}