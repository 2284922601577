.bottom-info {
	position: fixed;
	bottom: 65px;
	z-index: 199;
	width: 100%;
	padding: 0;
		font-weight: 400;
		font-size: 10px;
		line-height: 140%;
	padding: 8px 0 7px;
	background: var(--primary-600);

	font-family: var(--font-family);
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.07em;
	text-align: center;
	text-transform: capitalize;
	color: var(--white);

}
