.header {
    &__actions {
        display: none;
    
        @include tablet {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        }
    
        &--accessibility {
          display: flex;
          align-items: center;
    
          &::before {
            content: '\1F441';
            margin-right: 0.5rem;
          }
        }
    
        &--search {
          display: flex;
          align-items: center;
    
          &::before {
            content: '\1F50D';
            margin-right: 0.5rem;
          }
        }
    
        &--menu {
          display: flex;
          align-items: center;
    
          &::before {
            content: '\2630';
            margin-right: 0.5rem;
          }
        }
    }

.header__button {
    color: var(--primary);
    height: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--neutral);
    border-radius: 50%;
    gap:0.5rem;
    & .button__icon{
        display: flex;
        align-items: center;
        justify-content: center;
       
        height: 3rem;
        width: 3rem;
        
    }
    &.icon-only > .button__title {
        display: none;
    }
    & .button__title {
        font-size: 0.875rem;
        max-width: 90px;
        text-align: left;
        line-height: 1;
        letter-spacing: -.04rem;
    }
    
}