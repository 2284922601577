.appointment {
    &__wrapper {
        position: relative;
        width: calc(100%);
        @include tablet {
            width: calc(100%);
        }
    }
    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
    }
    &__header {
        position: relative;
        display: grid;
        grid-template-columns: 60px 140px auto;
        flex-direction: row;
        gap: 10px;
        font-weight: 600;
        font-size: 1rem;
        align-items: center;
        padding: 20px 10px;
        
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        @include tablet {
            grid-template-columns: 60px 240px auto;
        }

        & .cabinet {
        }

        & .doctor {
        }

        & .schedule {
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;
            @include tablet {
                justify-content: space-between;
            }

            & .day {
                width: 100px;
                font-size: 0.875rem;
                font-weight: 700;
                text-align: center;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-weight: 600;
        font-size: 1rem;
        padding: 20px 10px;
        background-color: var(--bg-neutral-100);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        z-index:200;
    }
    &__item {
        display: flex;
        flex-direction: col;
        gap: 10px;
        @include tablet {
            display: grid;
            grid-template-columns: 60px 240px auto;
        }

        & .cabinet {
            align-items: center;
            width: 60px;
        }

        & .doctor {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 140px;
            align-items: left;
            font-size: 1rem;
            line-height: 1.2;

            @include tablet {
                width: 240px;
            }

            &__specialty {
                font-size: 0.875rem;
                font-weight: 400;
            }
        }

        & .schedule {
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;

            @include tablet {
                justify-content: space-between;
            }

            & .time {
                width: 100px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap:4px;
                

                &__start, &__end {
                    display: flex;
                    flex-direction: row;
                    align-items: start;
                    font-size: 1.25rem;
                }

                &__hour {

                }

                &__minute {
                    font-size: 0.875rem;
                }
            }
        }
    }

    &__bg {
        position: absolute;
        background-color: var(--bg-neutral-50);
        width: 100%;
        height: 100%;
        z-index: 100;
    }
}