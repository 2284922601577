.component {
    &--documents {

        .documents {
            display: flex;
            flex-direction: column;
            column-gap: 1rem;
            row-gap: 1rem;
            
            &.documents--view-list {

                .documents {

                    &__list {
                        display: flex;
                        flex-direction: column;
                    }
                    
                    &__item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        column-gap: 1rem;
                        color: var(--accent-blue);
                        border-bottom: 0.125rem solid var(--bg-neutral-100);
                        padding: 1.25rem 0.7rem;
                        transition:background-color 0.3s ease;
                        
                        // &:first-child {
                        //     border-top: 0.125rem solid var(--primary);
                        // }
                        &:hover {
                            background-color: var(--primary);
                            color: var(--white);
                            border-bottom: 0.125rem solid var(--white);
                        }

                        &-icon {
                            flex-shrink: 0;
                            
                            svg {
                                height: 2rem;
                            }

                        }

                        &-title {
                            font-size: 1.1rem;
                            text-decoration: underline;
                        }
                    }
                }

                &.list-style-1 {
                    
                    .documents {

                        &__item {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            column-gap: 1rem;
                            color: var(--accent-blue);
                            border-bottom: 0.125rem solid var(--bg-neutral-100);
                            padding: 1.25rem 0.7rem;
                            transition:background-color 0.3s ease;
                            
                            &:hover {
                                background-color: var(--primary);
                                color: var(--white);
                                border-bottom: 0.125rem solid var(--white);
                            }
    
                            &-icon {
                                flex-shrink: 0;
                                
                                svg {
                                    height: 2rem;
                                }
    
                            }
    
                            &-title {
                                font-size: 1.1rem;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                
            }

            &.documents--view-card {

                .documents {
                    &__list {
                        display: grid;
                        grid-template-columns: 1fr;
                        @include tablet {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 1rem;
                        }
                        @include desktop-sm {
                            grid-template-columns: repeat(3, 1fr);
                        }
                    }
                    &__item {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;
                        color: var(--primary);
                        background-color: var(--bg-neutral-100);
                        border-radius: 0.5rem;
                        padding: 1.5rem 1.5rem;
                        transition:background-color 0.3s ease;
                        

                        &-icon {
                            flex-shrink: 0;                            
                            svg {
                                height: 1.5rem;
                            }
                        }

                        &-action {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            text-decoration: underline;
                            font-weight: 500;
                            gap: 0.5rem;
                            flex-shrink: 0;
                            svg {
                                height: 1.1rem;
                            }

                        }

                        &-title {
                            display: -webkit-box;
                            font-size: 1rem;
                            text-decoration: none;
                            min-height: 9.6rem;
                            max-height: 9.6rem;
                            text-overflow: ellipsis; /* Добавление многоточия */
                            -webkit-line-clamp: 8; /* Ограничение на количество строк */
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;

                        }

                        &:hover {
                            .documents__item-action {
                                text-decoration: underline;
                            }
                            .documents__item-icon svg {
                            }
                        }
                    }
                }

                &.card-square {
                    .documents {
                        &__item {
                            aspect-ratio: 1/1;
                        }
                    }

                   
                }

                &.card-style-3 {
                    .documents {
                        &__list {
                            display: grid;
                            grid-template-columns: 1fr;
                            gap: 1rem;

                            @include tablet {
                                grid-template-columns: repeat(2, 1fr);
                            }
                            @include desktop-sm {
                                grid-template-columns: repeat(3, 1fr);
                            }
                        }
                        &__item {
                            position: relative;
                            display: block;
                            color: var(--primary);
                            background-color: var(--bg-neutral-100);
                            border-radius: 0.5rem;
                            padding: 2rem 2rem;
                            transition:background-color 0.3s ease;
                            
                            &-wrapper {
                                position: relative;
                                z-index: 100;
                                display: flex;
                                flex-direction: column;
                            }
    
                            &-icon {
                                flex-shrink: 0;
                                position: absolute;
                                top: 1rem;
                                right: 1rem;
                                height: 2rem;
                                opacity: 1;
                                color: var(--white);
                                z-index: 99;
                                
                                svg {
                                    height: 2rem;
                                }
                            }
    
    
                            &-title {
                                display: -webkit-box;
                                font-size: 1rem;
                                font-weight: 500;
                                text-decoration: none;
                                max-height: 7.2rem;
                                -webkit-line-clamp: 6;
                                text-overflow: ellipsis; /* Добавление многоточия */
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                z-index: 100;

                                @include tablet {
                                    max-height: 9.6rem;
                                    -webkit-line-clamp: 8;
                                }

                                
                            }
    
                            &:hover {
                                background-color: var(--primary);
                                color: var(--white);
                                .documents__item-action {
                                    text-decoration: underline;
                                }
                                .documents__item-icon svg {
                                }
                            }
                        }
                    }
                }
                
            }

            &.documents--view-panel {
                
                .documents {
                    &__list {
                        @include tablet {
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;
                        }
                    }
                    &__item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        column-gap: 1rem;
                        font-weight: 2rem;
                        color: var(--primary);
                        background-color: var(--bg-neutral-100);
                        border-radius: 0.5rem;
                        padding: 1.25rem 1rem;
                        transition:background-color 0.3s ease;
                        

                        &-action {
                            
                            flex-shrink: 0;
                        }

                        &-title {
                            display: -webkit-box;
                            font-weight: 500;
                            font-size: 1rem;
                            text-decoration: none;
                            
                        }

                        &:hover {
                            .documents__item-action {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                &.panel-style-1 {
                    .documents {
                        &__list {
                            @include tablet {
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);
                                gap: 1rem;
                            }
                        }
                        &__item {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            column-gap: 1rem;
                            font-weight: 2rem;
                            color: var(--primary);
                            background-color: var(--bg-neutral-100);
                            border-radius: 0.5rem;
                            padding: 1.25rem 1rem;
                            transition:background-color 0.3s ease;
                            
    
                            &-action {
                                
                                flex-shrink: 0;
                            }
    
                            &-title {
                                display: -webkit-box;
                                font-weight: 500;
                                font-size: 1rem;
                                text-decoration: none;
                                max-height: 2.5rem;
                                text-overflow: ellipsis; /* Добавление многоточия */
                                -webkit-line-clamp: 2; /* Ограничение на количество строк */
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
    
                            &:hover {
                                .documents__item-action {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }

            }

            &.documents--single-file {

                .documents {
                    &__list {
                        @include tablet {
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;
                        }
                    }
                    &__item {
                        display: flex;
                        flex-direction: column;
                        background-color: var(--bg-neutral-100);
                        padding: 1.5rem 2.5rem;
                        border-radius: 0.25rem;
                        align-items: center;
                        &-title {
                            font-size: 1.25rem;
                            font-weight: 500;
                        }
                    }

                }
            }

            &.documents--with-category {

                &.documents--view-list {

                    .documents {

                        &__category {

                            .documents__category-label {
                                margin-top: 1rem;
                                margin-bottom: 0.75rem;
                            }
                        }
                    
                    }
                    
                }
                
            }

            &.documents--licenses {

                .documents {

                    &__list {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 1rem;
                    }
                    
                    &__item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        column-gap: 1rem;
                        color: var(--white);
                        border-bottom: 0.125rem solid var(--bg-neutral-100);
                        padding: 1rem 1.75rem;
                        padding-right: 2rem;
                        transition:background-color 0.3s ease;
                        background-color: var(--primary);
                        border-radius: 40rem;
                        
                        // &:first-child {
                        //     border-top: 0.125rem solid var(--primary);
                        // }
                        &:hover {
                            background-color: var(--bg-neutral-50);
                            color: var(--primary);
                            border-bottom: 0.125rem solid var(--white);
                        }

                        &-icon {
                            flex-shrink: 0;
                            
                            svg {
                                height: 2rem;
                            }

                        }

                        &-title {
                            font-size: 1.1rem;
                            text-decoration: underline;
                        }
                    }
                }
                
            }
        }

    }
}