.grid {
    display: grid;
}

#main {
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-height: calc(100vh - 130px);
    margin: 0 auto;
    margin-bottom: 110px;
    margin-top: 90px;
    color: var(--primary);
    width: 100%;
    padding: 0 15px;

    @include tablet {
        width: calc(100% - 80px);
        padding: 0 40px;
    }

    @include desktop-lg {
        width: $desktop-lg;
    }

    @include desktop-xl {
        width: $desktop-xl;
    }

    & .page-content {
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        width: 100%;
        row-gap: 12px;
        max-width: 1360px;
        position: relative;

        &--sidebar {

            @include desktop-sm {
                display: grid;
                grid-template-columns: calc(100% - 360px - 20px) 360px;
                column-gap: 20px;
            }

            @include desktop-md {
                grid-template-columns: calc(100% - 415px - 20px) 415px;
                column-gap: 20px;
            }

            @include desktop-lg {
                grid-template-columns: calc(100% - 415px - 20px) 415px;
                column-gap: 20px;
            }

            @include desktop-xl {
                grid-template-columns: calc(100% - 415px - 20px) 415px;
            }


            & .content {
                order: 2;
                display: block;

                background-color: var(--bg-neutral-50);
                border-radius: 0.5rem;

                @include desktop-sm {
                    order: 1;
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    padding: 1rem 2rem;
                }
            }

            & .sidebar {
                order: 1;
                display: flex;
                flex-direction: column;
                gap: 10px;
                position: sticky;
                top: 20px;
                padding: 20px;
                border-radius: 8px;
                height: fit-content;

                @include desktop-sm {
                    order: 2;
                }


            }




        }

    }
}