.home-slider {
	

	&__wrapper {
		display: flex;
		flex-direction: column;
		gap:30px;
	}

	&__topline {
		
	}

	.swiper--home-slider {
		height: 240px;
		position: relative;
		overflow-x: hidden;
	}
	
	.slider__item {
		height: auto;
		width: 100%;
		@include tablet {
			height: 220px;
			width: 428px;
		}
		background-color: var(--neutral);
		border-radius: $border-radius;
	}

	.slider__pagination {
		display: none;
		@include tablet {
			display: block;
		}
	}
	
	
	.swiper-container {
		position: relative;
		left: 0;
		width: 100%;
		padding-bottom: 32px !important;
	
		@include desktop-lg {
			padding-bottom: 20px !important;
		}
	}

	.swiper-wrapper {
        position: relative;
		left: 0;
        width: 100%;
        margin: 0 auto;
        @include desktop-lg {
            /* Дополнительные стили для desktop-lg */
        }
    }
	
	.swiper-pagination-bullet {
		width: 8px !important;
		height: 8px !important;
		background-color: var(--primary-100) !important;
		opacity: 1 !important;
	
		@include desktop-lg {
			width: 8px !important;
			height: 8px !important;
		}
	}
	
	.swiper-pagination-bullet-active {
		background-color: var(--primary) !important;
	}
	
	.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
		margin-left: 8px !important;
		margin-right: 8px !important;
	}
	
	.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
		bottom: 0 !important;
	}

}

