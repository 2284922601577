.home-hero {
    display: block;

    & .cards {
        display: grid;
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(4, auto);

        
        @include desktop-sm {
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(4, 1fr);
        }
        > .card {
            justify-content: space-between;
            align-items: flex-start;
            object-fit: fill;
            flex-flow: column;
            background-color: var(--primary);
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            align-self: auto;
            justify-self: auto;

            &-phonetable {  
                grid-area: span 1 / span 6;
                background-color: var(--primary);
                @include tablet {
                    grid-area: 1 / 1 / 2 / 7;
                }
                @include desktop-sm {
                    grid-area: 1 / 1 / 3 / 5;
                }
                @include desktop-md {
                    grid-area: 1 / 1 / 6 / 3;
                }

                .card__inner {
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: center;
                    gap: 10px;
                }
                & .list {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: center;
                    gap: 10px;
                }
                & .item {
                    display: grid;
                    grid-template-columns: 140px auto;
    
                    @include tablet {
                        grid-template-columns: auto auto;
                    }
                    
                    @include desktop-md{
                        grid-template-columns: 140px auto;
                    }
                    @include desktop-xl {
                        grid-template-columns: 240px auto;
                    }
                    align-items: center;
                    color: var(--white);
                    
                    
                    & .text {
                        display: flex;
                        flex-flow: column;
                        justify-self: start;
                        gap: 4px;
    
                        & .title {
                            font-size: 1rem;
                            line-height: 1;
                            font-weight: 400;
                            @include tablet {
                                font-size: 1.25rem;
                            }
                        }
    
                        & .subtitle {
                            font-size: 0.875rem;
                            font-weight: 300;
                            line-height: 1rem;
                            color: #D9D9D9;
                        }
                    }
    
                    & .line{
                        
                        &::before {
                            display: NONE;
                            @include tablet {
                                content: '';
                                display: block;
                                justify-self: center;
                                width: 100%;
                                height: 2px;
                                background: linear-gradient(90deg, rgba(80,80,80,0) 0%, rgba(211,210,210,1) 36%, rgba(211,210,210,1) 72%, rgba(211,210,210,0) 100%);
                            }
                            
                        }
                    }
    
                    & .phone {
                        justify-self: end;
                        font-size: 1rem;
                        font-weight: 500;
                        text-decoration: underline;
                        @include tablet {
                            font-size: 1.375rem;
                            text-decoration: none;
                        }
                    }
                }
            }

            &-links {
                grid-area: span 2 / span 6;
                @include tablet {
                    grid-area: 2 / 1 / 4 / 5
                }
                @include desktop-sm {
                    grid-area: 1 / 5 / 3 / 7;
                }
                @include desktop-md {
                    grid-area: 1 / 3 / 3 / 5;
                }
                background-color: #E31E24;
                height: 100%;
                .card__inner {
                    padding: 20px;
                    height: 100%;
                    display: flex;
                    flex-direction: column-reverse;
                }

                .items {
                    display: flex;
                    flex-direction: column;
                    gap:10px;

                    > a {
                        display: grid;
                        grid-template-columns: auto 20px;
                        align-items: center;
                        column-gap: 10px;
                        border-bottom: 2px solid var(--white);
                        padding-bottom: 10px;


                        .text {
                            font-size: 1.25rem;
                            line-height: 1.1;
                            font-weight: 400;
                            color: var(--white);
                        }
                        .icon {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            &-vk {
                grid-area: span 3 / span 6;
                @include tablet {
                    grid-area: 2 / 5 / 3 / 7
                }
                @include desktop-sm {
                    grid-area: 3 / 5 / 4 / 7;
                }
                @include desktop-md {
                    grid-area: 3 / 3 / 4 / 5;
                }
                background-color: #0077FF;
                .card__inner {
                    height: 100%;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                }
            }

            &-small-links {
                grid-area: span 4 / span 6;
                @include tablet {
                    grid-area: 3 / 5 / 4 / 7;
                }
                @include desktop-sm {
                    grid-area: 4 / 5 / 5 / 7;
                }
                @include desktop-md {
                    grid-area: 4 / 3 / 5 / 5;
                }
                background-color: unset;
                .card__inner {
                    display: grid;
                    grid-column-gap: 8px;
                    grid-row-gap: 8px;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: minmax(80px, auto);
                    @include desktop-sm {
                        grid-template-columns: repeat(1, 1fr);
                        grid-template-rows: repeat( 2, 80px);
                    }
                    @include desktop-md {
                        grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: minmax(80px, auto);
                    }
                }

                .link {
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    padding-left: 10px;
                    padding-right: 10px;
                    text-align: center;
                    border-radius: 16px;
                    background-color: var(--primary-700);
                    color: var(--white);
                    font-size: 1.25rem;
                    line-height: 1.1;

                }
            }

            &-news {
                grid-area: span 5 / span 6;
                display: flex;
                background-color: #fff0f0;
                color: var(--black);
                h3 {
                    font-size: 1.05rem;
                }
                .card__inner {
                    display: flex;
                    flex-direction: column;
                    gap: 18px;
                    padding: 20px;
                    @include tablet {
                        padding: 20px;
                    }
                }
                .card-heading {
                    display: flex;
                    justify-content: space-between;
                    & .title {

                        & h2 {
                            font-size: 1rem;
                            line-height: 1.625rem;
                            font-weight: 600;

                            @include tablet {
                                font-size: 1.15rem;
                            }
                        }

                    }

                    
                    & .link {
                        display:none;
                        @include tablet {
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                            align-items: center;
                            font-size: 0.875rem;
                            font-weight: 600;
                        }
                    }

                }
                @include tablet {
                    grid-area: 4 / 1 / 5 / 7;
                }
                @include desktop-sm {
                    grid-area: 3 / 1 / 5 / 5;
                }
                @include desktop-md {
                    grid-area: 1 / 5 / 6 / 7;
                }
                background-color: #fff0f0;

                .list {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    >.item {
                        display: flex;
                        flex-direction: row;
                        gap: 0;
                        padding-bottom: 8px;
                        border-bottom: 2px solid #D9D9D9;
                        
                        @include tablet {
                            gap: 24px;
                        }

                        .preview {
                            img {

                                display: none;
                                @include tablet {
                                    display: block;
                                    width: 60px;
                                    height: 60px;
                                    border-radius: 10px;
                                    object-fit: cover;
                                    min-width: 60px;
                                    min-height: 60px;
                                }
                               
                            }
                        }
                        .content {
                            display: flex;
                            flex-direction: column;
    
                            .top {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 8px;
                                font-size: 0.75rem;
                                color: var(--primary);
                                .category {
                                    font-size: 0.875rem;
                                    font-weight: 600;
                                }
                                .sepatator {
                                    &::before {
                                        content: '';
                                        position: static;
                                        display: block;
                                        width: 4px;
                                        height: 4px;
                                        border-radius: 20px;
                                        background-color: var(--primary);
                                    }
                                }
                                .date {
    
                                }                         
                            }
                            .title {
                                font-size: 1rem;
                                line-height: 1.1;
                            }
                        }
                    }

                }

                .item {
                    display: flex;
                    flex-direction: row;
                    gap: 24px;
                    padding-bottom: 8px;
                    border-bottom: 2px solid #D9D9D9;

                    .preview {
                        img {
                            width: 60px;
                            height: 60px;
                            border-radius: 10px;
                        }
                    }
                    .content {
                        display: flex;
                        flex-direction: column;

                        .top {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 8px;
                            font-size: 0.75rem;
                            color: var(--primary);
                            .category {
                                font-size: 0.875rem;
                                font-weight: 600;
                            }
                            .sepatator {
                                &::before {
                                    content: '';
                                    position: static;
                                    display: block;
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 20px;
                                    background-color: var(--primary);
                                }
                            }
                            .date {

                            }                         
                        }
                        .title {
                            font-size: 1rem;
                            line-height: 1.1;
                        }
                    }
                }
            }
        }
        
    } 
    

    & .card {

        &.news {
            display: flex;

            background-color: #fff0f0;
            .card-padding {
                display: flex;
                flex-direction: column;
                gap: 18px;
            }
            .card-heading {
                display: flex;
                justify-content: space-between;

                &>.title h2 {
                    font-size: 1.5rem;
                    line-height: 1.625rem;
                    font-weight: 600;
                }
            }

            & .news {

                &__list {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                &__item {
                    display: flex;
                    flex-direction: row;
                    gap: 24px;
                    padding-bottom: 8px;
                    border-bottom: 2px solid #D9D9D9;

                    .preview {
                        img {
                            width: 60px;
                            height: 60px;
                            border-radius: 10px;
                            object-fit: cover;
                            min-width: 60px;
                            min-height: 60px;
                        }
                    }
                    .content {
                        display: flex;
                        flex-direction: column;

                        .top {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 8px;
                            font-size: 0.75rem;
                            color: var(--primary);
                            .category {
                                font-size: 0.875rem;
                                font-weight: 600;
                            }
                            .sepatator {
                                &::before {
                                    content: '';
                                    position: static;
                                    display: block;
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 20px;
                                    background-color: var(--primary);
                                }
                            }
                            .date {

                            }                         
                        }
                        .title {
                            font-size: 1rem;
                            line-height: 1.1;
                        }
                    }
                }

            }
        }

        &.grid {
        
            
            & .card-links {
                height: 100%;
                background-color: #E31E24;
                border-radius: 20px;
                
            }
            & .card-vk {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #0077FF;
                border-radius: 20px;
                height: 100%;
                justify-content: center;
                align-items: center;

            }
            & .card-link {
                display: flex;
                background-color: var(--primary);
                border-radius: 20px;
                height: 100%;
                justify-content: center;
                align-items: center;
                color: var(--white);
                font-size: 1rem;
                line-height: 1.1;
                font-weight: 400;
                text-align: center;
                padding-top: 10px;
                padding-bottom: 10px;
                @include tablet {
                    font-size: 1.25rem;
                }
            }
        }
    }
}

.hero-links-map {
    
   
    display: none;

    @include tablet {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
        row-gap: 8px;
        column-gap: 8px;  
    }
        
    @include desktop-sm {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(1, 1fr);
    }

    .hero-helpful {

        @include desktop-sm {
            grid-area: 1 / 1 / 2 / 5;
        }
        
        background-color: var(--primary);
        color: var(--white);
        border-radius: 20px;

        &__wrapper {
            padding: 40px;
        }

        &__inner {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        &__heading {
            
            & .title {
                font-size: 1.375rem;
                font-weight: 500;
            }
        }

        &__content {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
        }

        &__column {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &__item {

            & a {
                font-size: 1.25rem;
                line-height: 1;
                font-weight: 400;
                text-decoration: underline;
            }
        }
    }

    .hero-map {
        
        background-color: var(--bg-neutral-50);
        border-radius: 20px;

        @include desktop-sm {
            grid-area: 1 / 5 / 2 / 7;
        }
    }
}