.progress-container {

    display: none;
    @include tablet {
        display: block;
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        z-index: 100;
        opacity: 0; 
        transform: translateY(0.5rem); 
        transition: opacity 0.5s ease, transform 0.5s ease;
    }
    
}

.progress-container.visible {
    opacity: 1; /* Появление */
    transform: translateY(0); /* Поднятие */
}

.progress-container::after {
    content: "↑"; /* Добавляем стрелку вверх */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: var(--secondary);; /* Цвет иконки */
    z-index: 10; /* Чтобы иконка была выше колеса */
}
.svg-content {
    transform: rotate(-90deg); /* Начало с верха */
}

.progress-path {
    stroke: var(--secondary);
    stroke-width: 4;
    fill: none;
}
