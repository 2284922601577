.component {
    &--phonebook {
        & .phonebook {

            & .phonebook__item {
            
                display: flex;
                flex-direction: column;
                justify-content: start;
                position: relative;
                align-items: start;
                @include tablet {
                    align-items: start;
                }
                border-bottom: 1px solid var(--bg-neutral-100);
                padding-bottom: 1rem;
                padding-top: 1rem;
                gap: 0.5rem;

                @include tablet {
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 2rem;
                    border-bottom: 1px solid var(--bg-neutral-100);
                }
                

                & .phonebook__label {
                    font-weight: 500;
                    @include tablet {
                       max-width: 20rem;
                    }
                }

                & .phonebook__phone
                {
                    flex-shrink: 0;
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    @include tablet {
                        text-align: right;
                    }

                    & .phone {
                        font-weight: 500;
                        font-size: 1.15rem;
                    }

                    & .worktime {
                        color: var(--primary-300);
                    }
                    
                }
            }

            & .phonebook__heading {
                font-weight: 500;
                color: var(--primary-200);
                margin-top: 1rem;
                @include tablet {
                }
            }
        }
    }
}