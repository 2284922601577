/* Base */
@import './base/reset';
@import './base/vars';
@import './base/mixins';
@import './base/fontsAutoGen';
@import './base/base';
@import './base/docs'; /* Отключить при необходимости */
@import './base/utils'; // Тест на ошибку
@import './base/containers';
@import './base/sticky-footer';
@import './base/buttons';

/* Blocks */
@import "blocks/_appointment.scss";
@import "blocks/_bottom-info.scss";
@import "blocks/_components.scss";
@import "blocks/_cursor.scss";
@import "blocks/_department.scss";
@import "blocks/_doctors.scss";
@import "blocks/_faq.scss";
@import "blocks/_footer.scss";
@import "blocks/_forms.scss";
@import "blocks/_general.scss";
@import "blocks/_grid.scss";
@import "blocks/_header.scss";
@import "blocks/_hero.scss";
@import "blocks/_main.scss";
@import "blocks/_mobile-nav.scss";
@import "blocks/_overlay-menu.scss";
@import "blocks/_overlays.scss";
@import "blocks/_pagination.scss";
@import "blocks/_sidebar.scss";
@import "blocks/_subdivision.scss";
@import "blocks/_test.scss";
@import "blocks/_text.scss";
@import "blocks/_top-info.scss";
@import "blocks/actions.scss";
@import "blocks/collapse.scss";
@import "blocks/grids.scss";
@import "blocks/news.scss";
@import "blocks/progress-circle.scss";
@import "blocks/sandbox.scss";
@import "blocks/toc.scss";
@import "blocks/components/_about-department.scss";
@import "blocks/components/_accessible.scss";
@import "blocks/components/_contact-list.scss";
@import "blocks/components/_contacts.scss";
@import "blocks/components/_docs.scss";
@import "blocks/components/_gallery.scss";
@import "blocks/components/_hero.scss";
@import "blocks/components/_info-list.scss";
@import "blocks/components/_list.scss";
@import "blocks/components/_mainnav.scss";
@import "blocks/components/_news-single.scss";
@import "blocks/components/_next-prev.scss";
@import "blocks/components/_orgs.scss";
@import "blocks/components/_personal.scss";
@import "blocks/components/_phonebook.scss";
@import "blocks/components/_pricelist.scss";
@import "blocks/components/_reception.scss";
@import "blocks/components/_requisites.scss";
@import "blocks/components/_reviews.scss";
@import "blocks/components/_search-result.scss";
@import "blocks/components/_sitemap.scss";
@import "blocks/components/_text-dedicated.scss";
@import "blocks/components/_text-with-link.scss";
@import "blocks/components/_text.scss";
@import "blocks/components/_timetable.scss";
@import "blocks/components/_toc-mobile.scss";
@import "blocks/components/_toc.scss";
@import "blocks/components/default.scss";
@import "blocks/components/timeline.scss";
@import "blocks/header/_main-nav.scss";
@import "blocks/home/_grid.scss";
@import "blocks/home/_home-banners.scss";
@import "blocks/home/_home-helpfull.scss";
@import "blocks/home/_home-hero.scss";
@import "blocks/home/_home-slider.scss";
@import "blocks/home/_map.scss";
@import "blocks/home/_slider.scss";


/* No styles code below. Only in modules */
/* Не пишите CSS код ниже. Только в подключаемых файлах */
