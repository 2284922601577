.component {
    &--news-single {

    }
    &.news-single {

        &--hero {

            & .breadscrumbs {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                gap:8px;
        
                & > .item {
                    font-size: 0.875rem;
                    line-height: 1.2;
                    color: var(--primary);
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    &::after {
                        
                    }
                }
        
            }

            &__title {
                .title {
                    font-size: 1.375rem;
                    font-weight: 700;
                    line-height: 1.2;
                    white-space: normal;
                    @include tablet {
                        font-size: 2rem;
                        line-height: 1.2;
                    }
                    @include desktop-md {
                        font-size: 2rem;
                    }
                    color: var(--primary);
                }
            }

            &__bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 10px;
            }

            &__date {
                font-weight: 500;
                color: var(--primary-200);
            }
        }

        &--hero2 {
            position: relative;
            background-color: var(--bg-neutral-50);
            border-radius: 2rem;
            overflow: hidden;
            .hero {
                &__background {

                }

                &__title {
                    position: absolute;
                    top: 0;
                    .title {
                        font-size: 1.375rem;
                        font-weight: 700;
                        line-height: 1.2;
                        white-space: normal;
                        @include tablet {
                            font-size: 2rem;
                            line-height: 1.2;
                        }
                        @include desktop-md {
                            font-size: 2rem;
                        }
                        color: var(--primary);
                    }
                }
    
                &__bottom {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 10px;
                    position: absolute;
                    bottom: 0;
                }

                &__date {
                    font-weight: 500;
                    color: var(--primary-200);
                }
            }
        }

        &--preview {
            display: block;
            width: 100%;
            border-radius: 1rem;
            overflow: hidden;
            object-fit: cover;
            &__img {
                width: 100%;
                max-width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        &--gallery {
            width: 100%;
            display: block;
            margin-top: 1rem;
            
            .gallery {
                &__wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
    
                }
    
                &__inner {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }
    
                &__top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
    
                    & .title {
                        font-size: 2.125rem;
                        line-height: 1;
                        font-weight: 500;
                    }
    
                    & .gallery__controls {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;
                    }
    
                    
                }    
    
                &__slider {
    
                    .swiper {
    
                    }
                    
                    .swiper-wrapper {
                        display: flex;
                        align-items: stretch;
    
                    }
                    .swiper-slide {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        -webkit-justify-content: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        -webkit-align-items: center;
                        align-items: center;
                    }
                
                    .swiper-slide img {
        
                    }
    
                }
            }
                
            
        }

        &--video {
            margin-top: 1rem;
        }

        &--text {
            margin-top: 1rem;
            display: flex;
            flex-direction: 0.5rem;
        }

        &--footer {
            margin-top: 1rem;
            border-top: 2px solid var(--primary-200);
            padding-top: 0.5rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & .news-single--footer {
                &__date {
                    font-size: 0.9rem;
                    font-weight: 500;
                    color: var(--primary-200);
                }
    
                &__source {
                    font-size: 0.9rem;
                    font-weight: 400;
                    color: var(--primary-200);
                }
            }
            
        }
    }
}