.footer {
    display: none;

    @include tablet {
        display: flex;
        flex-direction: column;
        background-color: var(--neutral);
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;

        &__wrapper {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            padding-top: 2rem;
            padding-bottom: 1rem;
        }

        &__top {
            display: flex;
            flex-direction: column;
            align-items: center;
        }



        &__body {
            display: flex;
            flex-direction: row;
            position: relative;
            align-items: stretch;
            gap: 2rem;
        }

        &__left {
            display: flex;
            flex-direction: column;
            max-width: 18rem;
            position: relative;

            @include desktop-sm {
                max-width: 20rem;
            }

            flex-shrink: 0;

            & .footer__bottom {
                position: absolute;
                bottom: 0;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                font-size: 0.75rem;
            }

            & .footer__links {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                white-space: nowrap;

                & a span {
                    color: inherit;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        width: 100%;
                        height: 1px;
                        background-color: var(--primary);
                        transform: scaleX(1);
                        transform-origin: bottom left;
                        transition: transform 0.3s ease;
                    }

                    &:hover:after {
                        transform: scaleX(0);
                        transform-origin: bottom right;
                    }
                }

            }

        }

        &__logo {
            margin-bottom: 1rem;
        }

        &__about,
        &__about p {
            font-size: 0.9rem;
        }

        &__right {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }


        &__departments {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;

            & h4 {
                opacity: 0.5;
            }

            .nav-departments {

                &__list {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 1rem;

                }

                &__item {
                    font-size: 1.25rem;
                    line-height: 1.3;

                    & a {
                        color: inherit;
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -2px;
                            width: 100%;
                            height: 2px;
                            background-color: var(--primary);
                            transform: scaleX(1);
                            transform-origin: bottom left;
                            transition: transform 0.3s ease;
                        }

                        &:hover:after {
                            transform: scaleX(0);
                            transform-origin: bottom right;
                        }
                    }
                }
            }
        }

        &__nav {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;

            & h4 {
                opacity: 0.5;
            }

            nav {

                & .columns-container {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    width: 100%;
                    gap: 2rem;

                    @include desktop-sm {
                        grid-template-columns: repeat(3, 1fr);
                    }
                }

                & .column {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 0.75rem;
                }

                & .item {
                    font-size: 1rem;
                    font-weight: 500;

                    & a {
                        color: inherit;
                        position: relative;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        &__contacts {

            display: flex;
            flex-direction: column;
            gap: 0.75rem;

            & .contacts__list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 12px;

                @include desktop-md {
                    grid-template-columns: repeat(4, 1fr);
                    gap: 0;
                }
            }

            & .contacts__item {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
                & .title {
                    font-size: 0.875rem;
                    color: var(--primary-200);
                    font-weight: 500;
                }

                & .phone {
                    font-size: 1.35rem;
                    font-weight: 600;
                }

                & .worktime {
                    font-size: 1rem;
                    font-weight: 500;
                    color: var(--primary-300);
                }

                &.address {

                    & .subaddress {
                        font-size: 1rem;
                        font-weight: 500;
                        color: var(--primary-200);
                    }

                    & .address {
                        font-size: 1.25rem;
                        font-weight: 600;
                    }

                    & .link {
                        font-size: 1rem;
                        color: var(--accent-blue);
                        font-weight: 500;

                        & a {
                            color: inherit;
                            position: relative;

                            &:after {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: -2px;
                                width: 100%;
                                height: 1px;
                                background-color: var(--accent-blue);
                                transform: scaleX(1);
                                transform-origin: bottom left;
                                transition: transform 0.3s ease;
                            }

                            &:hover:after {
                                transform: scaleX(0);
                                transform-origin: bottom right;
                            }
                        }
                    }
                }
            }
        }

        &__divider {
            width: 100%;
            height: 2px;
            background-color: var(--primary-50);
            margin-top: 0.75rem;
            margin-bottom: .75rem;
        }

        &__socials {
            .socials {
                &__list {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                }
                &__item {
                    & a {
                        color: inherit;
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -2px;
                            width: 100%;
                            height: 1px;
                            background-color: var(--accent-blue);
                            transform: scaleX(1);
                            transform-origin: bottom left;
                            transition: transform 0.3s ease;
                        }

                        &:hover:after {
                            transform: scaleX(0);
                            transform-origin: bottom right;
                        }
                    }
                }
            }
        }

        // &__bottom {
        //     display: flex;
        //     justify-content: space-between;
        //     padding-bottom: 14px;
        //     font-size: 0.75rem;
        // }

        // &__copyright {
        //     display: none;

        //     @include desktop-md {
        //         display: block;
        //     }
        // }

        // &__links {
        //     display: flex;
        //     gap: 0.75rem;
        //     white-space: nowrap;
        //     & a {
        //         color: inherit;
        //         position: relative;
        //         &:after {
        //             content: ''; 
        //             position: absolute; 
        //             left: 0;
        //             bottom: -2px;
        //             width: 100%;
        //             height: 1px; 
        //             background-color: var(--primary-750); 
        //             transform: scaleX(1); 
        //             transform-origin: bottom left; 
        //             transition: transform 0.3s ease; 
        //         }

        //         &:hover:after {
        //             transform: scaleX(0); 
        //             transform-origin: bottom right; 
        //         }
        //     }

        // }
    }
}

.crdrk__home {

    & .footer {
        display: none;
        background-color: inherit!important;

        @include tablet {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            &__wrapper {
                padding-left: 1.25rem;
                padding-right: 1.25rem;
                padding-top: 0;
                padding-bottom: 0;
            }

            &__contacts {

                display: flex;
                flex-direction: column;
                gap: 1rem;
                width: 100%;
                background-color: var(--neutral);
                padding: 1rem 2.5rem;
                border-radius: 20px;
    
                & .contacts__list {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 12px;
    
                    @include desktop-md {
                        grid-template-columns: repeat(4, 1fr);
                        gap: 0;
                        align-items: center;
                    }
                }
    
                & .contacts__item {
                    display: flex;
                    flex-direction: column;
                    gap: 0.375rem;
    
                    &.phone {
    
                        & .title {
                            font-size: 1rem;
                            color: var(--primary-200);
                            font-weight: 500;
                        }
    
                        & .phone {
                            font-size: 1.35rem;
                            font-weight: 600;
                        }
    
                        & .worktime {
                            font-size: 1rem;
                            font-weight: 500;
                            color: var(--primary-300);
                        }
                    }
    
                    &.address {
    
                        & .subaddress {
                            font-size: 1rem;
                            color: var(--primary-200);
                        }
    
                        & .address {
                            font-size: 1.25rem;
                            font-weight: 600;
                            
                        }
    
                        & .link {
                            font-size: 1rem;
                            font-weight: 500;
                            color: var(--accent-blue);
    
                            & a {
                                color: inherit;
                                position: relative;
    
                                &:after {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    bottom: -2px;
                                    width: 100%;
                                    height: 2px;
                                    background-color: var(--accent-blue);
                                    transform: scaleX(1);
                                    transform-origin: bottom left;
                                    transition: transform 0.3s ease;
                                }
    
                                &:hover:after {
                                    transform: scaleX(0);
                                    transform-origin: bottom right;
                                }
                            }
                        }
                    }
                }
            }

            &__bottom {
                display: flex;
                justify-content: space-between;
                padding-top: 1rem;
                padding-bottom: 0.75rem;
                font-size: 0.75rem;
            }
    
            &__copyright {
                display: none;
    
                @include desktop-md {
                    display: block;
                }
            }
    
            &__links {
                display: flex;
                gap: 0.75rem;
                white-space: nowrap;
                & a {
                    color: inherit;
                    position: relative;
                    &:after {
                        content: ''; 
                        position: absolute; 
                        left: 0;
                        bottom: -2px;
                        width: 100%;
                        height: 1px; 
                        background-color: var(--primary); 
                        transform: scaleX(1); 
                        transform-origin: bottom left; 
                        transition: transform 0.3s ease; 
                    }
    
                    &:hover:after {
                        transform: scaleX(0); 
                        transform-origin: bottom right; 
                    }
                }
    
            }
        }
    }

}