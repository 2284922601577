.component {
    &--about-department {
        gap: 0.375rem;
        flex-direction: column;
        display: flex;
        position: relative;
        background-color: var(--primary);
        border-radius: 1rem;
        padding: 1.25rem;
        color: var(--white);

        @include desktop-sm {
            display: none;
        }

        .about-department {

            &--phones {

                .phones__list {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
        
                    .phones__item {
                        display: flex;
                        flex-direction: column;
                        gap: 0.125rem;

                        .phones__phone {
                            font-size: 1.15rem;
                        }
                    }
        
                    }
            }
            

            &--timetable {

                .timetable {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
        
                    .timetable__item {
                        display: flex;
                        flex-direction: row;
                        justify-content: start;
                        @include tablet {
                            justify-content: start;
                        }
                        gap: 0.5rem;

                        .days {
                            font-size: 1.15rem;
                        }

                        .worktime {
                            font-size: 1.15rem;
                        }
                    }
        
                }
                
            }
        
            &--leader, &--phones, &--timetable {
                border-bottom: 2px solid var(--primary-100);
                padding-bottom: 0.5rem;
            }
        }
    }
    
}