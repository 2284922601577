.topline {
    background: var(--primary);
    height: 40px;
}
.middle {
    height: 90px;
}
.content-1 {
    background-color: var(--primary-100);
    height: 100%;
    width: 100%;
}
.d-none {
    display: none;
}

.test {
    display: block;
    position: relative;

    & .list-items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        
        font-size: 2rem;
        color: #000;

        & .item {
            background-color: var(--primary);
            aspect-ratio: 4/3;
            object-fit: fill;
        }
    }
}