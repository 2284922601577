$gallery-pagination-color: #333;
$gallery-button-bg: rgba(255, 255, 255, 0.8);

.component {
  &--gallery {
    padding: 20px 0;

    .component__swiper {
      position: relative;
      overflow: hidden;

      .component__slide {
        text-align: center;

        .component__image {
          max-width: 100%;
          height: auto;
          border-radius: 8px;
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .component__pagination {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);

        .swiper-pagination-bullet {
          width: 12px;
          height: 12px;
          background-color: #ccc;
          opacity: 1;
          margin: 0 5px;
          transition: background-color 0.3s ease;

          &-active {
            background-color: $gallery-pagination-color;
          }
        }
      }

      .component__button-next,
      .component__button-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        background-color: $gallery-button-bg;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba(255, 255, 255, 1);
        }

        &::after {
          font-size: 20px;
          color: #333;
        }
      }

      .component__button-next {
        right: 10px;
      }

      .component__button-prev {
        left: 10px;
      }

      .swiper-lazy-preloader {
        display: none;
      }
      
      .swiper-lazy-loading .swiper-lazy-preloader {
        display: block;
      }
      
      .swiper-lazy-loaded ~ .swiper-lazy-preloader {
        display: none;
      }
    }
  }
}