.component {
    &--visit-time {
        & .visit-time {
            &__list {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;
                background-color: var(--primary);
                color: var(--white);
                border-radius:  1rem;
                padding: 2rem;

                & h5 {
                    margin-bottom: 1rem;
                    opacity: 0.7;
                    font-size: 1.45rem;
                }
            }
            &__item {
                
                    display: flex;
                    flex-direction: column;
                    @include tablet {
                        flex-direction: row;  
                    }
                    align-items: start;
                    gap: 0.5rem;
                    width: 100%;
           
                    &--time {
                        font-size: 1.15rem;
                        justify-content: space-between;
                        padding-top: 0.5rem;
                        @include tablet {
                            width: 36rem;
                        }
                    
                    }

                    &--category {
                        font-size: 1.15rem;
                        justify-content: space-between;  
                        @include tablet {
                            width: 36rem;
                        }
                        
                        & h5 {
                            font-size: 1.25rem;
                        }
                    }

                    &--text {
                        margin-top: 1rem;
                        @include tablet {
                            width: 36rem;
                        }
                    }
                & .separator {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    &:before {
                        content: '';
                        display: flex;
                        align-items: center;
                        width: 1rem;
                        height: 2px;
                        background-color: var(--white);
                    }
                }
                & .label {
                    text-align: left;
                }

                & .value {
                    font-weight: 500;
                    flex-shrink: 0;
                    @include tablet {
                        text-align: right;
                    }
                    
                }
            }
        }
    }
}