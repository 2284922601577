.sidebar {

    &__component {

        
        flex-direction: column;
        flex-shrink: 0;
        flex-wrap: nowrap;
        gap: 1rem;
        display: flex;

        &.sidebar__component {

            &--toc {
                background-color: var(--bg-neutral-100);
                // border: 1px solid var(--bg-neutral-50);
                border-radius: 1rem;
    
                
                .toc__title {
                    z-index: 2;
                    // border-bottom: 1px solid var(--bg-neutral-50);
                    padding: 1.25rem;
                    padding-bottom: 0;
                    margin-bottom: 0.5rem;
                    position: sticky;
                    top: 0%;
                    right: 0;
        
                    & h3 {
                        font-size: 1rem;
                        font-weight: 500;
                        line-height: 32px;
                    }
                }

                .toc__table {
                    overflow: scroll;
                    overflow-x: hidden;
                    max-height: 60vh;
                    padding: 1.25rem;
                    padding-top: 0;
                    
                    &.toc-style-1 {

                        & .toc__list {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: .25rem;
                            row-gap: 0.5rem;
                        }

                        & .toc__item {
                            display: flex !important;
                            width: auto !important;
                            align-items: center !important;
                            border-radius: .5rem !important;
                            padding: .375rem .75rem !important;
                            font-size: 15px !important;
                            font-weight: 400 !important;
                            line-height: 1.25rem !important;
                            transition-property: opacity !important;
                            transition-timing-function: cubic-bezier(.4,0,.2,1) !important;
                            transition-duration: .15s !important;
                            background-color: var(--bg-neutral-50);

                            &.active {
                                background-color: var(--primary);
                                color: var(--bg-neutral-50);
                                font-weight: 400 !important;
                            }
                        }

                        & .toc__link {
                            
                        }
                    }
                }
            }

            &--subdivision-nav {

                background-color: var(--bg-neutral-100);
                // border: 1px solid var(--bg-neutral-50);
                border-radius: 1rem;
                padding: 1.25rem;

                & .subdivision-nav {

                    &__title {

                    }

                    &__content {


                    }

                    &__list {
                        display: flex;
                        flex-direction: column;
                        gap:0.25rem;
                        row-gap: 0.5rem;
                    }

                    &__item {
                        position: relative;
                    }

                    &__link {

                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        gap: 8px;
                        padding-top: 0.35rem;
                        padding-bottom: 0.7rem;
                        font-weight: 500;

                        &:before {
                            content: "";
                            position: absolute;
                            bottom: 0.125rem;
                            left: 0;
                            right: 0;
                            height: 0.1rem;
                            background-color: var(--primary);
                            -webkit-transition: .35s;
                            transition: all .35s ease;
                        }

                        
                    }
                }
            }

            &--collections-list {

                background-color: var(--bg-neutral-100);
                // border: 1px solid var(--bg-neutral-50);
                border-radius: 1rem;
                padding: 1.25rem;

                & .collections-list {

                    &__title h3{
                        color: var(--primary-300);
                        font-weight: 500;
                        font-size: 1.25rem;
                    }

                    &__content {


                    }

                    

                    &__list {
                        display: flex;
                        flex-direction: column;
                        gap:0.25rem;
                    }

                    &__item {
                        position: relative;
                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 0.125rem;
                            left: 0;
                            right: 0;
                            height: 0.1rem;
                            background-color: var(--primary);
                            -webkit-transition: .35s;
                            transition: all .35s ease;
                        }
                    }

                    &__link {

                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 8px;
                        padding-top: 0.35rem;
                        padding-bottom: 0.7rem;
                        font-weight: 500;

                        .icon {
                            flex-shrink: 0;
                        }
                        

                        
                    }

                    &__actions {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        flex-wrap: wrap;
                        margin-top: 1rem;
                        
                        & a {
                            text-decoration: underline;
                            font-weight: 500;

                        }
                    }
                }
            }
            
            &--about-department {

                background-color: var(--primary);
                // border: 1px solid var(--bg-neutral-50);
                border-radius: 1rem;
                padding: 1.25rem;
                color: var(--white);
                display: none;

                @include desktop-sm {
                    display: flex;
                    

                }

                & .about-department {

                    &--leader {
                        display: flex;
                        flex-direction: column;
                        gap: 0.25rem;
                    }

                    &--phones {
                        display: flex;
                        flex-direction: column;
                        gap: 0.25rem;


                        .phones {

                            &__list {
                                display: flex;
                                flex-direction: column;
                                gap: 0.25rem;
                            }
                            &__item {
                                display: flex;
                                flex-direction: column;
                                gap: 0.125rem;
                                width: 100%;
                                align-items: center;

                                @include desktop-sm {
                                    display: grid;
                                    grid-template-columns: 6rem auto;

                                }
                            }
                            &__name {
                                justify-self: start;
                            }
                            &__phone {
                                justify-self: end;
                                flex-shrink: 0;
                                font-size: 1.15rem;

                            }
                        }
                    }

                    &--timetable {
                        display: flex;
                        flex-direction: column;
                        gap: 0.25rem;
                        .timetable {
                            display: flex;
                            flex-direction: column;
                            gap: 0.5rem;
                
                            .timetable__item {
                                display: flex;
                                flex-direction: row;
                                justify-content: start;
                                gap: 0.5rem;
        
                                .days {
                                    font-size: 1.05rem;
                                }
        
                                .worktime {
                                    font-size: 1.05rem;
                                    flex-shrink: 0;
                                }
                            }
                
                        }
                    }
                }
            }





        }
    }
   



    &__elem {
        display: block;
        position: relative;
        background-color: var(--primary);
        border-radius: 0.5rem;
        color: var(--white);
        &--nav {
            
            .wrapper {

            }

            .inner {
                padding: 1rem 1.5rem;
            }

            .nav {

                &__list {
                    display: flex;
                    flex-direction: column;
                    gap:0.5rem;
                }

                &__item {
                    font-size: 1.25rem;
                    border-bottom: 2px solid var(--white);
                }
            }
        }
    }
}