.component {
    &--search-result {

        h3 {
            font-size: 1.75rem;
            font-weight: 600;    
        }  

        & .search-results {
            display: flex;
            flex-direction: column;
            gap: 1rem;

                          
            &__counter {
                color: var(--primary-200);
                font-weight: 200;
            }

            &__no-results {
                
            }

            &__results {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                
            }

            &__item {
                display: flex;
                flex-direction: column;
                gap: 0.75rem;
                color: var(--primary);
                padding-bottom: 1rem;
                border-bottom: 2px solid var(--bg-neutral-100);

                & .title {
                    color: var(--accent-blue);
                    font-size: 1.5rem;
                    font-weight: 500;
                }

                & .context {
                    font-size: 1.15rem;
                }

                &:hover .title{
                    text-decoration: underline;
                    transition: text-decoration 0.3s ease;
                }
            }
        }
    }
}