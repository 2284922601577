.personals {
    &--doctors {
        .doctors {
            &__list {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
            
            &__header {
                display: none;
        
                @include desktop-sm {
                    font-size: 1.125rem;
                    line-height: 1.2;
                    padding: 20px;
                    background: #F2F4FA;
                    font-weight: 500;
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        
            &__body {
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-size: 1.125rem;
                line-height: 1.2;
        
                @include desktop-sm {
                    gap: 40px;
                }
            }
        
            &__item {
                display: flex;
                flex-direction: column;
                border-bottom: 2px solid var(--bg-neutral-100);
                
        
                @include desktop-sm {
                    display: grid;
                    grid-template-columns: 1fr 1fr 2fr;
                    padding-bottom: 20px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
        
                &--name {
                    font-weight: 500;
                }
        
                &--specialty {
                   
                }
        
                &--additional {
                    display: flex;
                    flex-direction: column;
                    padding-top: 20px;
                    padding-bottom: 10px;
                    gap: 10px;
        
                    @include desktop-sm {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        padding-top: 0;
                        padding-bottom: 20px;
                    }
        
                    & .category,  & .department {
                        display: flex;
                        flex-direction: column;
                        font-weight: 500;
                        gap: 4px;
        
                        @include desktop-sm {
                            font-weight: 400;
                            font-size: 1.125rem;
                        }
                    }
        
                    & .category .subtitle,  & .department .subtitle{
                        font-size: 0.875rem;
                        font-weight: 300;
        
                        @include desktop-sm {
                            display: none;
                        }
                    }
        
                    & .category {
                        
        
                    }
        
                    & .department {
        
                        &--list {
                            display: flex;
                            flex-direction: column;
                            gap: 6px;
        
                            @include desktop-sm {
                                display: block;
                            }
                        }
                    }
        
                    & .link-more {
                        font-size: 1rem;
                        text-align: right;
                        text-decoration: underline;
                        @include desktop-sm {
                            display: none;
                        }
                    }
                    
                }
        
                
            }
        
            &__footer {
                display: flex;
                flex-direction: column;
            }
        
        }
    }
}

.personals {
    &--experts {
        .experts {
            &__wrapper {
            }

            &__inner {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
            
            &__heading {
                display: none;
            }
        
            &__content {
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-size: 1rem;
                line-height: 1.2;
        
                @include desktop-sm {
                    gap: 40px;
                }

                &-inner {

                }
            }

            &__list {
                &--cards {
                    display: flex;
                    flex-direction: column;
                    gap:10px;

                    @include tablet {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 20px;
                    }

                    @include desktop-sm {
                        grid-template-columns: repeat(3, 1fr);
                    }

                    .expert {
                        color: var(--white);
                        background-color: var(--primary-600);
                        border-radius: 0.5rem;
                        @include tablet {
                           
                        }
                        
                        @include desktop-sm {
                           
                        }
                        
                        &__inner {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            padding: 2rem;
                            align-items: center;
                            text-align: center;
                        }

                        &__name {
                            font-size: 1.25rem;
                            font-weight: 500;
                        }
                        
                        &__job {
                            font-size: 1rem;
                            font-weight: 500;
                        }
                    }
                }
            }
        
        }
    }

    &--leaders {
        .leaders {
            &__wrapper {
                display: block;

            }
            &__inner {

            }
            &__list {

                &--big-cards {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    .card {
                        display: block;
                        width: 100%;
                        max-width: 100%;
                        
                        border-bottom: 4px solid var(--bg-neutral-100);
                        @include tablet {
                            background-color: var(--bg-neutral-100);
                            border-radius: 1rem;
                        }

                        &__wrapper {
                            padding: 0px;
                            @include tablet {
                                padding: 2.45rem;
                            }

                        }
                        &__inner {
                            display: flex;
                            flex-direction: column;

                            @include tablet {
                                gap: 1rem;
                            }
                            @include desktop-sm {
                                flex-direction: row;
                                gap: 3rem;
                            }
                        }
                        &__left {
                            
                        }
                        &__right {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            @include desktop-sm {
                                justify-content: space-between;
                            }
                        }
                        &__info {
                        }
                        &__bottom {
                            display: flex;
                            flex-direction: column;
                        }
                        &__action {
                            display: none;
                            @include desktop-sm {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                justify-content: end;
                            }

                            & .btn {
                                text-decoration: underline;
                                font-size: 1.25rem;
                            }
                        }
                        .leader {
                            &__photo {
                                display: none;
                                @include tablet {
                                    display: block;
                                    
                                }
                                > img {
                                    width: 320px;
                                    height: 320px;
                                    object-fit: cover;
                                    object-position: center;
                                    border-radius: 1rem;
                                }
                            }
                            &__fio {
                                font-size: 2rem;
                                font-weight: 700;
                            }
                            &__job {
                                font-size: 1.25rem;
                                font-weight: 500;
                            }
                            &__shortdesc {
                                margin-top: 1rem;
                                font-size: 1rem;
                            }
                            &__contacts {
                                display: flex;
                                flex-direction: column;
                                gap: 6px;
                                background-color: unset;
                                @include tablet {
                                    background-color: var(--bg-neutral-100);
                                    border-radius: 0.5rem;
                                }
                                @include desktop-sm {
                                    margin-top: 2rem;
                                }
                                .item {
                                    background-color: var(--primary);
                                    color: var(--white);
                                    display: flex;
                                    flex-direction: column;
                                    padding: 12px 10px;
                                    align-items: center;
                                    @include tablet {
                                        flex-direction: row;
                                        justify-content: space-between;
                                        padding: 1rem;
                                        gap: 0px;
                                    }
                                    .subtitle {
                                        font-size: 0.875rem;
                                        font-weight: 300;
                                        @include tablet {
                                            font-size: 1rem;
                                            font-weight: 500;
                                        }
                                    }

                                    .title {
                                        font-size: 1.1rem;
                                        font-weight: 500;
                                        @include tablet {
                                            font-size: 1.1rem;
                                            font-weight: 700;
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.component {
    &.personal {
        &--doctors {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .filters {
                display: flex;
                flex-direction: column;
                background-color: var(--bg-neutral-50);
                padding: 0.75rem 1.25rem;
                gap: 1rem;
                border-radius: 0.25rem;
                @include tablet {
                    flex-direction: row;
                    gap: 2rem;
                }
                input {
                    border: 1px solid var(--bg-neutral-100);
                    background-color: var(--white);
                    padding: 0.75rem 1.25rem;
                    border-radius: 50rem;
                }
                select {
                    border: 1px solid var(--bg-neutral-100);
                    background-color: var(--white);
                    padding: 0.75rem 1.25rem;
                    border-radius: 50rem;
                    appearance: none; /* Убираем стандартный стиль */
                    -webkit-appearance: none; /* Для Safari */
                    -moz-appearance: none; /* Для Firefox */
                }

                select:focus-visible {
                    outline: 2px solid var(--primary);
                }
            }

            .personal {
                &__list {
                    display: flex;
                    flex-direction: column;
                    font-size: 1.05rem;
                    
                    & .list{

                        &__header {
                            display: none;
                            @include tablet {
                                display: grid;
                                grid-template-columns: 280px 2fr 2fr;
                                column-gap: 0.25rem;
                                row-gap: 0.5rem;
                                background-color: var(--bg-neutral-100);
                                font-weight: 500;
                                padding: 1.5rem 1.25rem;
                                justify-content: flex-start;

                            }
                        }
                        &__body {
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;
                        }

                        &__item {
                            display: flex;
                            flex-direction: column;
                            gap: 0.75rem;
                            border-bottom: 2px solid var(--bg-neutral-100);
                            padding: 0.75rem 1.25rem;
                            
                            
                            @include tablet {
                                display: grid;
                                grid-template-columns: 16rem 1fr;
                                column-gap: 0.25rem;
                                row-gap: 0.5rem;
                                padding: 0.75rem 1.25rem;
                                justify-content: flex-start;
                            }
                            & .name {
                                font-size: 1.125rem;
                                font-weight: 500;
                            }

                            & .lists-container {
                                display: flex;
                                flex-direction: column;
                                gap: 0.75rem;
                                align-items: flex-start;
                                    @include tablet {
                                        align-items: flex-end;
                                    }

                                & .job, & .department {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    gap: 0.25rem;
                                    font-size: 0.9rem;
                                    font-weight: 500;
                                    align-items: flex-start;
                                    @include tablet {
                                        align-items: flex-end;
                                    }
                                    
                                    
    
                                    & li {
                                        background-color: var(--primary);
                                        color: var(--white);
                                        padding: 0.5rem 1rem;
                                        border-radius: 50rem;
                                        white-space: nowrap;
    
                                    }
                                }
    
                                & .job li {
                                    background-color: var(--bg-neutral-100);
                                    color: var(--primary);
                                }
                            }
                            
                        }
                    
                    }
                }
            }
        }
    }
}