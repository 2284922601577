.component {
    &--info-list {
        & .info-list {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            
            &.info-list--two-columns {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                & .info-list__item {
                
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    position: relative;
                    align-items: start;
                    @include tablet {
                        align-items: center;
                    }
                    border-bottom: 1px solid var(--bg-neutral-100);
                    padding-bottom: 1rem;
                    gap: 0.5rem;
                    @include tablet {
                        flex-direction: row;
                        justify-content: space-between;
                        gap: 2rem;
                        border-bottom: 2px solid var(--bg-neutral-100);
                    }
                    
                    
           
                

                & .label {
                    font-weight: 500;
                    @include tablet {
                       max-width: 20rem;
                    }
                }

                & .value {
                    text-align: left;
                    font-size: 1.15rem;
                    font-weight: 400;
                    @include tablet {
                        font-size: 1.05rem;
                        text-align: right;
                    }
                    
                }
                }
            }

            &.info-list--contacts {

                & .contacts__item {
                
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    position: relative;
                    align-items: start;
                    @include tablet {
                        align-items: center;
                    }
                    border-bottom: 1px solid var(--bg-neutral-100);
                    padding-bottom: 1rem;
                    gap: 0.5rem;

                    @include tablet {
                        flex-direction: row;
                        justify-content: space-between;
                        gap: 2rem;
                        border-bottom: 1px solid var(--bg-neutral-100);
                    }
                    
                    & .label {
                        font-weight: unset;
                        @include tablet {
                           max-width: 20rem;
                        }
                    }

                    &.contact--phone .contact__phone,
                    &.contact--email .contact__email,
                    &.contact--address .contact__address {
                        flex-shrink: 0;
                        text-align: left;
                        font-weight: 500;
                        font-size: 1.15rem;
                        @include tablet {
                            font-size: 1.05rem;
                            text-align: right;
                        }
                        
                    }

                    &.contact--phone {

                        & .contact__label {

                        }
                        & .contact__phone {
                            
                        }

                    }

                    &.contact--email {

                        & .contact__label {

                        }
                        & .contact__email {
                            
                            
                        }
                        
                    }

                    &.contact--website {
                        & .contact__link {
                            & a.website {
                                font-size: 1.05rem;
                                color: var(--accent-blue);
                                text-decoration: underline;
                            }
                        }
                    }

                    &.contact--address {
                        & .contact__address {
                           @include tablet {
                               max-width: 16rem;
                           }
                        }
                    }

                    &.contact--divider {
                        & .contact__divider {
                            font-weight: 500;
                            font-size:  1.15rem;
                            color: var(--primary--700);
                        }
                        
                    }
                }
            }
        }
            
    }  
}